import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";

const FilterCheckboxList = ({
  filterValues,
  appliedFilter: checkedValues,
  setAppliedFilter: setCheckedValues,
  category,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const searchOnFilterValues = (values) =>
    searchQuery
      ? values.filter((value) => String(value).toLowerCase().includes(searchQuery.toLowerCase()))
      : values;

  const handleToggle = (value) => {
    const newCheckedValues = checkedValues.includes(value)  
      ? checkedValues.filter((x) => x !== value)
      : [...checkedValues, value];
    setCheckedValues(newCheckedValues);
  };

  return (
    <List component="div" disablePadding dense className='filters-section'>
      <ListItem>
        <TextField
          className={"filter-search-box"}
          id={`${category}-filter-search-box`}
          type={"text"}
          size="small"
          placeholder={`Find ${_.lowerCase(category)}`}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
      </ListItem>
      {searchOnFilterValues(filterValues).map((filterValue, i) => (
        <ListItem
          key={`dimension-val-${i + 1}`}
          onClick={() => handleToggle(filterValue)}
          sx={{ pl: 3 }}
        >
          <Checkbox
            id={`filters-checkbox-${filterValue}`}
            edge="start"
            checked={
              checkedValues.indexOf(filterValue) !== -1
            }
            tabIndex={-1}
            disableRipple
            size={"small"}
            className="filters-checkbox"
            onChange={()=> {}}
          />
          <ListItemText>{filterValue}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

FilterCheckboxList.propTypes = {
  filterValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  appliedFilter: PropTypes.arrayOf(PropTypes.string),
  setAppliedFilter: PropTypes.func.isRequired,
  category: PropTypes.string,
};

export default FilterCheckboxList;
