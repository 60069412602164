import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import List from "@mui/material/List";
import { ListItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import { useSendAnalytics } from "../analytics/analytics";
import eventTypes from "../analytics/eventTypes";

const SiteSelectionList = ({
  filterValues,
  appliedFilter: site,
  setAppliedFilter: setSite,
  category,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const sendAnalytics = useSendAnalytics();
  const onSiteSelected = (event) => {
    sendAnalytics(eventTypes.mrp.mrpFilterAction, { type: 'site',  item: event.target.value, action: 'select' });
    setSite(event.target.value);
  };
  const searchOnFilterValues = (values) =>
    searchQuery
      ? values.filter((value) => String(value).toLowerCase().includes(searchQuery.toLowerCase()))
      : values;

  return (
    <RadioGroup
      aria-labelledby="site-selection"
      name="site-selection"
      value={site}
      onChange={onSiteSelected}
      className='filters-section'
    >
      <List component="div" disablePadding={true}>
        <ListItem key={'site-selection-li'}>
          <TextField
            className={"filter-search-box"}
            id={"site-filter-search-box"}
            type={"text"}
            size="small"
            placeholder={`Find ${_.lowerCase(category)}`}
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
          />
        </ListItem>
        {searchOnFilterValues(filterValues).map((site, index) => (
          <ListItem sx={{ pl: 3 }} key={`site-selection-li-${index}`}>
            <FormControlLabel
              key={site}
              value={site}
              control={<Radio size="small" checkedIcon={<CheckCircleRoundedIcon size={"small"} sx={{ width: '15px' }} />} icon={<CircleOutlined size={"small"} sx={{ width: '15px' }} />} />}
              label={site}
            />
          </ListItem>
        ))}
      </List>
    </RadioGroup>
  );
};

SiteSelectionList.propTypes = {
  appliedFilter: PropTypes.string.isRequired,
  filterValues: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
  setAppliedFilter: PropTypes.func.isRequired,
  category: PropTypes.string,
};

export default SiteSelectionList;
