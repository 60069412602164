import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import { useContractRatioOutOfSpec } from './hooks';
import ScopeDataGrid from "../../components/widgetLib/ScopeDataGrid";
import { numberColumn, renderCellWithTooltip } from "../../utils/gridColumns";

const ContractRatioOutOfSpec = ({ contractId }) => {
  const { data, isLoading, isError } = useContractRatioOutOfSpec(contractId);

  const columns = [
    {
      field: 'customer',
      headerName: 'Customer',
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'key_account_manager',
      headerName: 'Key Account Manager',
      flex: 1,
      renderCell: renderCellWithTooltip,
    },
    {
      field: 'product_family',
      headerName: 'Product',
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'item_code',
      headerName: 'Item Code',
      flex: 1,
      renderCell: renderCellWithTooltip,
    },
    {
      field: 'issue',
      headerName: 'Issue In',
      flex: 1,
      renderCell: renderCellWithTooltip,
    },
    {
      field: 'brix_range',
      headerName: 'Brix Range',
      flex: 1,
      ...numberColumn(),
    },
    {
      field: 'ac_range',
      headerName: 'Acidity Range',
      flex: 1,
      ...numberColumn(),
    },
    {
      field: 'ratio_range',
      headerName: 'Ratio Range',
      flex: 1,
      ...numberColumn(),
    },
  ];
    
  if (isLoading) {
    return <Loader/>;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  if (data.length > 0) {
    return (
      <>
        <Typography className="contract-section-title">
            Ratio out of spec
        </Typography>
        <ScopeDataGrid
          columns={columns}
          rows={data}
          isCellEditable={() => false}
          getRowId={(row) => row.id}
          style={{
            width: '100%',
          }}
          isAutoHeight={true}
        />
      </>
    );
  }
  return <></>;
};

ContractRatioOutOfSpec.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default ContractRatioOutOfSpec;
