import Palette from '../../../styling/palette.scss';

export const kpiColor = {
  'inventory': {
    'positive': { color: Palette.DarkThemeError, bg: 'rgba(255, 0, 0, .2)' },
    'negative': { color: 'green', bg: 'rgba(51, 170, 51, .2)' },
  },
  'expected_overstock': {
    'positive': { color: Palette.DarkThemeError, bg: 'rgba(255, 0, 0, .2)' },
    'negative': { color: 'green', bg: 'rgba(51, 170, 51, .2)' },
  },
  'expected_miss_revenue': {
    'positive': { color: Palette.DarkThemeError, bg: 'rgba(255, 0, 0, .2)' },
    'negative': { color: 'green', bg: 'rgba(51, 170, 51, .2)' },
  },
  'non_moving_inventory': {
    'positive': { color: Palette.DarkThemeError, bg: 'rgba(255, 0, 0, .2)' },
    'negative': { color: 'green', bg: 'rgba(51, 170, 51, .2)' },
  },
  'turnover': {
    'positive': { color: Palette.DarkThemeError, bg: 'rgba(255, 0, 0, .2)' },
    'negative': { color: 'green', bg: 'rgba(51, 170, 51, .2)' },
  },
  'revenue': {
    'positive': {  color: 'green', bg: 'rgba(51, 170, 51, .2)' },
    'negative': { color: Palette.DarkThemeError, bg: 'rgba(255, 0, 0, .2)' },
  },
};