import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import config from '../config';

const backendAPI = axios.create({
  baseURL: config.REACT_APP_BACKEND_URL,
});

const useAccessToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery({
    queryFn: () => getAccessTokenSilently(),
    queryKey: ['backend_api'],
    staleTime: Infinity,
  });
};

export const useBackendAPIGetRequest = ({ queryKey, path, onDataReceived, ...options }) => {
  const { data: accessToken } = useAccessToken();
  const query = useQuery({
    queryKey: [...queryKey, accessToken],
    queryFn: () => (
      backendAPI.get(path, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      })
        .then((res) => onDataReceived ? onDataReceived(res.data) : res.data)
    ),
    enabled: !!accessToken && (options.enabled || options.enabled === undefined),
    placeholderData: [],
  });
  return { data: query.data, isLoading: query.isLoading || query.isPlaceholderData, isError: query.isError };
};
