import React from 'react';
import PropTypes from 'prop-types';
import ScopeDataGrid from './widgetLib/ScopeDataGrid';
import ScopeDrawer from "./widgetLib/ScopeDrawer";
import DemandFulfillmentGauge from './widgetLib/DemandFulfillmentGauge';
import { useOpenContractDrawer } from '../containers/Contract/hooks';
import ContractDetailedFulfillment from '../containers/Contract/ContractDetailedFulfillment';
import { getWeekMonthForDisplay } from '../utils/dateTime';
import {
  currencyColumn,
  numberColumn,
  renderCellWithTooltip,
} from "../utils/gridColumns";

const ContractedDemandTable = ({ data, demandColumnsToShow, view, firstDemandColumn, isAutoHeight, withMetadata, withDemandQuantity, withVirtualContracts, elementHeight, ...props }) => {
  const [selectedFulfillmentCell, setSelectedFulfillmentCell] = React.useState(null);
  const [selectedFulfillmentContract, setSelectedFulfillmentContract] = React.useState(null);
  const firstColumn = firstDemandColumn===undefined ? 0 : data.data_columns.indexOf(firstDemandColumn);
  const openContractDrawer = useOpenContractDrawer();

  const metadataColumns = [
    {
      field: 'doc_number',
      headerName: 'Contract',
      renderCell: renderCellWithTooltip,
      cellClassName: 'clickable',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'site',
      headerName: 'Site',
      minWidth: 60,
      flex: 0.7,
    },
    {
      field: 'family',
      headerName: 'Family',
      renderCell: renderCellWithTooltip,
      minWidth: 100,
      flex: 0.9,
    },
    {
      field: 'sub_family',
      headerName: 'Sub Family',
      renderCell: renderCellWithTooltip,
      minWidth: 110,
      flex: 0.9,
    },
    {
      field: 'product_family',
      headerName: 'Product Family',
      renderCell: renderCellWithTooltip,
      minWidth: 110,
      flex: 0.9,
    },
    {
      field: 'product_line',
      headerName: 'Product Line',
      renderCell: renderCellWithTooltip,
      minWidth: 110,
      flex: 0.9,
    },
    {
      field: 'item_code',
      renderCell: renderCellWithTooltip,
      headerName: 'Item Code',
      minWidth: 120,
      flex: 0.8,
    },
    {
      field: 'customer',
      headerName: 'Customer',
      renderCell: renderCellWithTooltip,
      minWidth: 120,
      flex: 0.8,
    },
    {
      field: 'customer_group',
      headerName: 'Customer Group',
      renderCell: renderCellWithTooltip,
      minWidth: 120,
      flex: 0.8,
    },
    {
      field: 'asp',
      headerName: 'ASP (ton)',
      minWidth: 100,
      flex: 0.7,
      valueGetter: ({ value }) => value.trim() === 'N/A' ? { value: -1, currency: 'USD' }: { value: Number(value.split(' ')[0]), currency: value.split(' ')[1] },
      ...currencyColumn(2, 2, 'compact'),
    },
  ];
  const dataCols = data.data_columns.length > 0 ? data.data_columns.slice(firstColumn, data.data_columns.length): [];

  let columns = [
    {
      field: 'recipe',
      headerName: 'Recipe',
      renderCell: renderCellWithTooltip,
      flex: 0.5,
      minWidth: 80,
    },    
    {
      field: 'origin_text',
      headerName: 'Origin',
      minWidth: 110,
      flex: 0.8,
    },
    {
      field: 'pack_type',
      headerName: 'Pack Type',
      minWidth: 90,
      flex: 0.7,
    },
    {
      field: 'open_quantity',
      headerName: 'Open Quantity',
      flex: 0.7,
      minWidth: 100,
      ...numberColumn(),
    },
  ];

  const fulfillment_columns = [
    ...dataCols.map((column) => ({
      field: column.toString(),
      headerName: getWeekMonthForDisplay(view, column),
      renderCell: (params) => ((params.value && params.value.demand)
        ? <DemandFulfillmentGauge value={params.value}/>
        : '─'
      ),
      valueFormatter: ({ value }) => value ? `${value.demand}`: '-',
      minWidth: 140,
      flex: 0.8,
      cellClassName: (params) => params.value.fulfillment > 0 && !params.row.doc_number.startsWith('virtual_contract') ? 'clickable' : '',
    })),
  ];

  if (withDemandQuantity) {
    columns = [
      ...columns,
      {
        field: 'usedquantity',
        headerName: 'Demand Quantity',
        flex: 0.7,
        minWidth: 100,
        ...numberColumn(),
      },
    ];
  }
  
  return (
    <>
      <ScopeDataGrid
        columns={withMetadata ? [...metadataColumns, ...columns, ...fulfillment_columns] : [...columns, ...fulfillment_columns]}
        rows={data.data}
        getRowId={(row) => row.doc_number}
        onCellClick={(params) => {
          if (params.field === 'doc_number' && !params.value.startsWith('virtual_contract')){
            openContractDrawer(params.value);
          }
          if (dataCols.includes(params.field) && params.value.fulfillment > 0 && !params.row.doc_number.startsWith('virtual_contract')) {
            setSelectedFulfillmentCell(params.field);
            setSelectedFulfillmentContract(params.id);
          }
        }}
        style={{ width: '100%' }}
        isAutoHeight={isAutoHeight}
        elementHeight={elementHeight}
        {...props}
      />
      { selectedFulfillmentCell && (
        <ScopeDrawer
          open
          onClose={() => {
            setSelectedFulfillmentCell(null);
            setSelectedFulfillmentContract(null);
          }}
          className="contract-detailed-drawer"
          content={
            <ContractDetailedFulfillment
              view={view}
              contractId={selectedFulfillmentContract}
              selectedTime={selectedFulfillmentCell}
            />
          }
        />)}
    </>
  );
};

ContractedDemandTable.propTypes = {
  data: PropTypes.object.isRequired,
  demandColumnsToShow: PropTypes.number.isRequired,
  view: PropTypes.oneOf(['week', 'month']),
  firstDemandColumn: PropTypes.string,
  isAutoHeight: PropTypes.bool.isRequired,
  withMetadata: PropTypes.bool.isRequired,
  withVirtualContracts: PropTypes.oneOf(['include', 'exclude']),
  elementHeight: PropTypes.number,
};

ContractedDemandTable.defaultProps = {
  demandColumnsToShow: 6, // TODO: this should be a const or a customer-level config
  view: 'week',
  isAutoHeight: false,
  withMetadata: true,
  withDemandQuantity: false,
  withVirtualContracts: 'include',
};

export default ContractedDemandTable;
