import React from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Setting from "./Setting";
import "./styles.scss";

const SettingsContainer = () => {
  const { category } = useParams();

  const isDisabled = (settingCategory) => settingCategory !== category;

  return (
    <div className="settings-container">
      <Card className={`settings-category-card ${isDisabled('spec') ? 'disabled': ''}`}>
        <CardHeader
          title={"Spec"}
          className="settings-category-card-title"
        />
        <CardContent className="settings-category-card-content">
          <List>
            <Setting
              settingTitle="Safety & Over Stock"
              settingInput={
                <React.Fragment>
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    placeholder="Safety Stock"
                    defaultValue={3}
                    label="Safety Stock"
                    disabled={isDisabled("spec")}
                  />
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    placeholder="Over Stock"
                    defaultValue={8}
                    label="Over Stock"
                    disabled={isDisabled("spec")}
                  />
                </React.Fragment>
              }
            />
            <Divider />
            <Setting
              settingTitle="Block Purchase Recommendations"
              settingInput={
                <React.Fragment>
                  <DatePicker
                    label="From"
                    value={new Date()}
                    onChange={(newValue) => {
                      // eslint-disable-next-line no-console
                      console.log(newValue); // TODO: replace with real functionality
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    label="To"
                    value={new Date()}
                    onChange={(newValue) => {
                      // eslint-disable-next-line no-console
                      console.log(newValue); // TODO: replace with real functionality
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </React.Fragment>
              }
            />
          </List>
        </CardContent>
      </Card>
      <Card className={`settings-category-card ${isDisabled('appearance') ? 'disabled': ''}`}>
        <CardHeader
          title={"Appearance"}
          className="settings-category-card-title"
        />
        <CardContent className="settings-category-card-content">
          <List>
            <Setting
              settingTitle="Unit of measurement"
              settingInput={
                <Select
                  style={{ borderRadius: "3rem", width: "10rem" }}
                  disabled={isDisabled("appearance")}
                  defaultValue=''
                  displayEmpty
                  name="settings-measurement-select"
                >
                  <MenuItem value={""}>Select</MenuItem>
                  <MenuItem value={"ton"}>Ton</MenuItem>
                  <MenuItem value={"tonne"}>Tonne</MenuItem>
                  <MenuItem value={"kg"}>Kg</MenuItem>
                </Select>
              }
            />
            <Divider />
            <Setting
              settingTitle="Default Number of Weeks"
              settingInput={
                <TextField
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  defaultValue={8}
                  label="Weeks"
                  disabled={isDisabled("appearance")}
                />
              }
            />
            <Divider />
            <Setting
              settingTitle="Week Starting Day"
              settingInput={
                <Select
                  style={{ borderRadius: "3rem", width: "10rem" }}
                  disabled={isDisabled("appearance")}
                  defaultValue=''
                  displayEmpty
                  name="settings-start-day-select"
                >
                  <MenuItem value={""}>Select</MenuItem>
                  <MenuItem value={"monday"}>Monday</MenuItem>
                  <MenuItem value={"tuesday"}>Tuesday</MenuItem>
                  <MenuItem value={"wednesday"}>Wednesday</MenuItem>
                  <MenuItem value={"thursday"}>Thursday</MenuItem>
                  <MenuItem value={"friday"}>Friday</MenuItem>
                  <MenuItem value={"saturday"}>Saturday</MenuItem>
                  <MenuItem value={"sunday"}>Sunday</MenuItem>
                </Select>
              }
            />
          </List>
        </CardContent>
      </Card>
    </div>
  );
};

export default SettingsContainer;
