import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

const KPI = ({ title, content, footer, ...props }) => (
  <Card {...props}>
    <CardHeader
      title={<Typography variant="h5" style={{ textAlign: 'center' }}>{title}</Typography>}
      sx={{ justifyContent: 'center' }}
    />
    <CardContent style={{ textAlign: 'center' }}>
      {content}
    </CardContent>
    <CardActions>
      {footer}
    </CardActions>
  </Card>
);

KPI.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  footer: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default KPI;
