import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Checkbox from "@mui/material/Checkbox";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useSendAnalytics } from '../../analytics/analytics';
import eventTypes from '../../analytics/eventTypes';
import {
  getLeaves,
  hasChildren,
  nodeHasChildrenWithQuery,
  searchOnFilterValues,
  nodePropTypeshape,
} from './helpers';

const NestedFilterNode = ({ node, appliedFilter, setAppliedFilter, searchQuery }) => {
  const sendAnalytics = useSendAnalytics();
  const [isOpen, setIsOpen] = React.useState(false);

  const nodeLeaves = getLeaves(node);
  const selectedLeaves = nodeLeaves.filter((item) => appliedFilter.includes(item));
  const indeterminate = selectedLeaves.length > 0 && selectedLeaves.length < nodeLeaves.length;
  const checked = nodeLeaves.every((item) => appliedFilter.includes(item));

  React.useEffect(() => { // used to expand product families if an item code inside them matches the query
    if (searchQuery && nodeHasChildrenWithQuery(node, searchQuery)) {
      setIsOpen(true);
    }
    if (!searchQuery) {
      setIsOpen(false);
    }
  }, [searchQuery, node]);

  const handleParentNodeCheck = (checked) => {
    if (checked) {
      sendAnalytics(eventTypes.mrp.mrpFilterAction, { type: node.type,  item: node.name, action: 'select' });
      setAppliedFilter(_.uniq([...appliedFilter, ...nodeLeaves]));
    }
    else {
      sendAnalytics(eventTypes.mrp.mrpFilterAction, { type: node.type,  item: node.name, action: 'deselect' });
      setAppliedFilter(appliedFilter.filter((item) => !nodeLeaves.includes(item)));
    }
  };

  const handleLeafNodeCheck = (checked) => {
    if (checked) {
      sendAnalytics(eventTypes.mrp.mrpFilterAction, { type: node.type,  item: node.name, action: 'select' });
      setAppliedFilter([...appliedFilter, node.name]);
    }
    else {
      sendAnalytics(eventTypes.mrp.mrpFilterAction, { type: node.type,  item: node.name, action: 'deselect' });
      setAppliedFilter(appliedFilter.filter((item) => item !== node.name));
    }
  };

  if (hasChildren(node)) {
    return (
      <React.Fragment>
        <ListItemButton
          onClick={(event) => {
            if (event.target.checked === undefined) {
              setIsOpen(!isOpen);
            }
          }}
          style={{ padding: '0 1rem' }}
        >
          <ListItemIcon>
            <Checkbox
              id={`filters-checkbox-${node.name}`}
              edge="start"
              checked={checked}
              indeterminate={indeterminate}
              tabIndex={-1}
              disableRipple
              size={"small"}
              className="filters-checkbox"
              onChange={(event)=> handleParentNodeCheck(event.target.checked)}
            />
          </ListItemIcon>
          <ListItemText primary={node.name} />
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ ml: '1rem' }} dense>
            {searchOnFilterValues(node.children, searchQuery).map((child) => (
              <NestedFilterNode
                key={child.name}
                node={child}
                appliedFilter={appliedFilter}
                setAppliedFilter={setAppliedFilter}
                searchQuery={searchQuery}
              />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  }
  else {
    return (
      <ListItemButton
        key={node.name}
        onClick={() => handleLeafNodeCheck(!appliedFilter.includes(node.name))}
      >
        <ListItemIcon>
          <Checkbox
            id={`filters-checkbox-${node.name}`}
            edge="start"
            checked={appliedFilter.includes(node.name)}
            tabIndex={-1}
            disableRipple
            size={"small"}
            className="filters-checkbox"
            onChange={(event)=> handleLeafNodeCheck(event.target.checked)}
          />
        </ListItemIcon>
        <ListItemText primary={node.name} />
      </ListItemButton>
    );
  }
};

NestedFilterNode.propTypes = {
  node: nodePropTypeshape,
  appliedFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  setAppliedFilter: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
};

export default NestedFilterNode;
