import { 
  getISOWeeksInYear ,
  getISOWeek ,
  getMonth,
  format,
} from 'date-fns';

export const sortWeeksInYear = (weekList) => { // currently not in use but might be in the future
  const amountOfWeeksInYear = getISOWeeksInYear(new Date().getFullYear());  
  const currentWeek = getISOWeek(new Date());
  return [...weekList].sort((a, b) => ((a - currentWeek) % amountOfWeeksInYear) - ((b - currentWeek) % amountOfWeeksInYear));
};

const getCurrentWeekNumber = () => getISOWeek(new Date());

const getCurrentMonth = () => getMonth(new Date());

export const getCurrentTimeUnitNumber = (timeUnit) => {
  switch (timeUnit) {
    case 'week':
      return getCurrentWeekNumber();
    case 'month':
      return getCurrentMonth();
    default:
      return 0;
  }
};

export const getTodayForExport = () => (format(new Date(), 'yyyy-MM-dd'));

export const getWeekMonthForDisplay = (view, value) => {
  const values = value.split('_');
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return view === 'week' ? `W${values[0]} ${values[1]}` : `${months[values[0]-1]} ${values[1]}`;
};
