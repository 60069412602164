import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import './styles.scss';


const ErrorMessage = ({ header, messageContent }) => (
  <div className="ErrorMessage">
    <Card elevation={0} classes={{ root: 'ErrorCard' }}>
      <h4>{header}</h4>
      {typeof messageContent === 'string' && <p>{messageContent}</p>}
      {typeof messageContent === 'object' && messageContent.map((line, index) => <p
        key={`line-${index + 1}`}>{line}</p>)}
    </Card>
  </div>
);

ErrorMessage.defaultProps = {
  header: 'Oops... something went wrong',
  messageContent: 'Please contact us, and we\'ll figure this out asap.',
};
ErrorMessage.propTypes = {
  header: PropTypes.string.isRequired,
  messageContent: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};

export default ErrorMessage;
