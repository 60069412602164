import { useSendAnalytics } from '../../analytics/analytics';
import eventTypes from '../../analytics/eventTypes';
import { useBackendAPIGetRequest } from '../../connectors/apis';
import { useSelectedContract } from '../../state/hooks';

export const useOpenContractDrawer = () => {
  const sendAnalytics = useSendAnalytics();
  const { setSelectedContract } = useSelectedContract();

  return (contractId) => {
    sendAnalytics(eventTypes.contract.contractDrawerOpened, { contract_id: contractId });
    setSelectedContract(contractId);
  };
};

export const useContractOrders = (contractId) =>
  useBackendAPIGetRequest({
    queryKey: ['contract_orders', contractId],
    path: `contract/${contractId}/orders`,
    enabled: !!contractId,
  });

export const useContractOrdersGraph = (contractId) =>
  useBackendAPIGetRequest({
    queryKey: ['contract_orders_graph', contractId],
    path: `contract/${contractId}/orders_graph`,
    enabled: !!contractId,
  });

export const useContractDetails = (contractId) =>
  useBackendAPIGetRequest({
    queryKey: ['contract_details', contractId],
    path: `contract/${contractId}/details`,
    enabled: !!contractId,
  });

export const useContractKPIs = (contractId) =>
  useBackendAPIGetRequest({
    queryKey: ['contract_KPIs', contractId],
    path: `contract/${contractId}/kpis`,
    enabled: !!contractId,
  });

export const useContractFulfillment = (contractId) => (
  useBackendAPIGetRequest({
    queryKey: ['contract_fulfillment', contractId],
    path: `contract/${contractId}/fulfillment`,
    enabled: !!contractId,
  })
);

export const useContractNextMonthPO = (contractId) =>
  useBackendAPIGetRequest({
    queryKey: ['contract_next_month_pos', contractId],
    path: `contract/${contractId}/next_month_pos`,
    enabled: !!contractId,
  });

export const useContractMissingSpecDetails = (contractId) =>
  useBackendAPIGetRequest({
    queryKey: ['contract_missing_spec_details', contractId],
    path: `contract/${contractId}/missing_spec_details`,
    enabled: !!contractId,
  });

export const useContractRatioOutOfSpec = (contractId) =>
  useBackendAPIGetRequest({
    queryKey: ['contract_ratio_out_of_spec', contractId],
    path: `contract/${contractId}/ratio_out_of_spec`,
    enabled: !!contractId,
  });

export const useContractDetailedFulfillmentForTime = (contractId, selectedTime, view = 'week') => {
  let path = `contract/${contractId}/fulfillment_for_time?view=${view}&selected_time=${selectedTime}`;
  return useBackendAPIGetRequest({
    queryKey: ['contract_fulfillment_for_time', path],
    path: path,
    enabled: !!contractId,
  });
};
