import React from 'react';
import { useServiceWorkerRegistration } from '../state/hooks';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

const ReloadSnackBar = () => {
  const { serviceWorkerRegistration } = useServiceWorkerRegistration();

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };
  
  return (
    <Backdrop
      sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={!!serviceWorkerRegistration}
    >
      <Snackbar
        open={!!serviceWorkerRegistration}
        message="New Version Available"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        action={(
          <Button color="primary" size="small" onClick={updateServiceWorker}>
            UPDATE
          </Button>
        )}
      />
    </Backdrop>
  );
};

export default ReloadSnackBar;
