import Grid from "@mui/material/Grid";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { Outlet } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import AppLeftToolBox from './containers/AppLeftToolBox';
import AppHeader from "./containers/AppHeader";
import Contract from './containers/Contract/Contract';
import { ScopeDarkTheme } from './scopeThemes';
import {
  REDIRECT_AFTER_LOGIN_PATH_KEY,
  renderMRPDrillDownByType,
} from './constants';
import {
  useDrilldownCurrentColumn,
  useDrilldownCurrentColumnName,
  useDrilldownItemCode,
  useDrilldownType,
  useMRPView,
  useVirtualContracts,
  useSelectedContract,
  useSite,
} from './state/hooks';
import ScopeDrawer from './components/widgetLib/ScopeDrawer';
import Loader from './components/Loader';
import ReloadSnackBar from './components/ReloadSnackBar';
import { useSendAnalytics } from './analytics/analytics';
import eventTypes from './analytics/eventTypes';
import "./App.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10 * 60 * 1000,
    },
  },
});


const App = () => {
  const { error, logout, loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  const { selectedContract, setSelectedContract } = useSelectedContract();
  const { drilldownItemCode, setDrilldownItemCode } = useDrilldownItemCode();
  const { drilldownCurrentColumn } = useDrilldownCurrentColumn();
  const { drilldownType } = useDrilldownType();
  const { drilldownCurrentColumnName } = useDrilldownCurrentColumnName();
  const { mrpView } = useMRPView();
  const { site } = useSite();
  const { virtualContractsView } = useVirtualContracts();
  const sendAnalytics = useSendAnalytics();
  if (isLoading) {
    return <Loader/>;
  }

  if (error) {
    return <div className="ErrorMessage">
      <Card elevation={0} classes={{ root: 'ErrorCard' }}>
        <h4>Access denied</h4>
        <p>We could not find a user for this email</p>        
        <Button
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }} variant="outlined">Go back to Login</Button>
      </Card>
    </div>;
  }

  if (!isAuthenticated) {
    localStorage.setItem(REDIRECT_AFTER_LOGIN_PATH_KEY, JSON.stringify({
      pathname: window.location.pathname,
      search: window.location.search,
    }));
    loginWithRedirect({ redirectUri: `${window.location.origin}/redirect` });
    return <Loader/>;
  }

  sendAnalytics(eventTypes.global.sessionStart);

  const Layout = !!drilldownType && !!drilldownItemCode ? renderMRPDrillDownByType[drilldownType]: null;

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={ScopeDarkTheme}>
        <CssBaseline/>
        <div className="App">
          <Grid container spacing={0}>
            <Grid container item xs={12} className="app-header">
              <AppHeader/>
            </Grid>
            <Grid container spacing={0} columns={19} className="app-body">
              <Grid item xs={1}>
                <AppLeftToolBox/>
              </Grid>
              <Grid item xs={18} sx={{ height: '100%' }}>
                <Outlet/>
              </Grid>
            </Grid>
          </Grid>
          {!!selectedContract && <ScopeDrawer
            onClose={() => setSelectedContract(null)}
            className={'contract-drawer'}
            content={
              <div style={{ padding: '0.5rem', width: '70vw' }}>
                <Contract contractId={selectedContract} />
              </div>
            }
            anchor={'right'}
          />}
          {!!Layout &&
              <ScopeDrawer
                onClose={() => setDrilldownItemCode(null)}
                className={'mrp-cell-drilldown-drawer'}
                content={<Layout itemCode={drilldownItemCode} currentColumn={drilldownCurrentColumn} currentColumnName={drilldownCurrentColumnName} view={mrpView} virtual_contracts_view={virtualContractsView} site={site} />}
                anchor={'right'}
              />
          }
        </div>
        <ReloadSnackBar/>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
