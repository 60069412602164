import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = ({ size }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    }}
  >
    <CircularProgress size={size}/>
  </div>
);

Loader.defaultProps = {
  size: '3rem',
};
Loader.propTypes = {
  size: PropTypes.string.isRequired,
};

export default Loader;
