import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { APP_WINDOW_HEIGHT } from '../../constants';
import { useFulfillmentNestedFilterValues } from './hooks';
import FulfillmentFilters from './FulfillmentFilters';
import FulfillmentTable from './FulfillmentTable';
import FilterChips from '../../components/FilterChips';
import { useNestedFilterChips } from '../../components/nestedFilterList/helpers';
import VirtualContractsToggle from "../../components/widgetLib/VirtualContractsToggle";
import WeekMonthToggle from '../../components/widgetLib/WeekMonthToggle';
import { useSendAnalytics } from '../../analytics/analytics';
import eventTypes from '../../analytics/eventTypes';
import './styles.scss';

const Fulfillment = () => {
  const [view, setView] = useState('week');
  const [virtualContractsView, setVirtualContractsView] = useState('include');
  const [mode, setMode] = useState('all');
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedItemCodes, setSelectedItemCodes] = useState([]);
  const [elementHeight, setElementHeight] = React.useState(0);
  const ref = React.useRef(null);
  const sendAnalytics = useSendAnalytics();
  const nestedFilterChips = useNestedFilterChips({
    useFilterValues: useFulfillmentNestedFilterValues,
    selectedFilters: selectedItemCodes,
    setSelectedFilters: setSelectedItemCodes,
    analyticsEventType: eventTypes.fulfillment.fulfillmentFilterChipRemoved,
  });

  const handleToggleView = (_, newView) => {
    if (newView) {
      sendAnalytics(eventTypes.fulfillment.fulfillmentWeekMonthToggle, { view: newView });
      setView(newView);
    }
  };

  const handleToggleMode = (event) => {
    const newMode = event.target.value;
    sendAnalytics(eventTypes.fulfillment.fulfillmentModeToggle, { mode: newMode });
    setMode(newMode);
  };

  const handleVirtualContractsToggle = (_, newView) => {
    if (newView) {
      sendAnalytics(eventTypes.fulfillment.fulfillmentVirtualContractsToggle, { view: newView });
      setVirtualContractsView(newView);
    }
  };

  const filters = {
    site: selectedSites,
    customer: selectedCustomers,
    item_code: selectedItemCodes,
  };

  const siteFilterChips = selectedSites.map((site) => ({
    label: 'site',
    value: site,
    onDelete: () => {
      sendAnalytics(eventTypes.fulfillment.fulfillmentFilterChipRemoved, { 'type': 'site', 'item': site });
      setSelectedSites(selectedSites.filter((s) => s !== site));
    },
  }));

  const customerFilterChips = selectedCustomers.map((customer) => ({
    label: 'customer',
    value: customer,
    onDelete: () => {
      sendAnalytics(eventTypes.fulfillment.fulfillmentFilterChipRemoved, { 'type': 'customer', 'item': customer });
      setSelectedCustomers(selectedCustomers.filter((c) => c !== customer));
    },
  }));

  React.useEffect(() => {
    if (ref.current) {
      setElementHeight(ref.current.clientHeight);
    }
  }, []);

  return (
    <Grid
      container
      item
      spacing={1}
      columnSpacing={1}
      className="fulfillment-page"
      height={`${APP_WINDOW_HEIGHT}vh`}
    >
      <Grid item xs={2} style={{ height: '100%' }}>
        <FulfillmentFilters
          selectedSites={selectedSites}
          selectedCustomers={selectedCustomers}
          setSelectedSites={setSelectedSites}
          setSelectedCustomers={setSelectedCustomers}
          appliedNestedFilter={selectedItemCodes}
          setAppliedNestedFilter={setSelectedItemCodes}
        />
      </Grid>
      <Grid item xs={10} style={{ height: '100%' }}>
        <Stack spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            className="fulfillment-page-header"
          >
            <div className="filter-chip-area">
              <FilterChips filterChips={[...siteFilterChips, ...nestedFilterChips, ...customerFilterChips]}/>
            </div>
            <WeekMonthToggle
              view={view}
              handleToggleView={handleToggleView}
            />
            <VirtualContractsToggle
              handleToggle={handleVirtualContractsToggle}
              selectedValue={virtualContractsView}
            />
            <Select
              value={mode}
              onChange={handleToggleMode}
              color="primary"
              className="fulfillment-mode-selection"
              name="fulfillment-select"
            >
              <MenuItem value={"all"}>Show All Contracts</MenuItem>
              <MenuItem value={"fulfilled"}>Show Only Fulfilled Contracts</MenuItem>
              <MenuItem value={"unfulfilled"}>Show Only Unfulfilled Contracts</MenuItem>
            </Select>
          </Stack>
        </Stack>
        <Grid item container sx={{ height: '96%', width: '100%' }} ref={ref} justifyContent="center" alignItems="flex-start">
          <FulfillmentTable
            view={view}
            mode={mode}
            filters={filters}
            virtualContractsView={virtualContractsView}
            elementHeight={elementHeight}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Fulfillment;
