import { 
  init,
  setUserId,
  track,
}  from '@amplitude/analytics-browser';
import config from '../config';
import { useAuth0 } from '@auth0/auth0-react';

export const initAnalytics = () => {
  init(config.REACT_APP_AMPLITUDE_API_KEY, undefined, {
    appVersion: config.version,
  });
};

const sendAnalytics = (eventType, eventPayload, userId) => {
  setUserId(userId);
  track(eventType, eventPayload);
};

export const useSendAnalytics = () => {
  const { user } = useAuth0();
  let email = null;
  if (user) {
    email = user.email;
  }
  return (eventType, eventPayload) => sendAnalytics(eventType, eventPayload, email);
};
