import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import { useProductionRecommendationDrillDown } from '../hooks';
import Loader from '../../../components/Loader';
import ErrorMessage from '../../../components/ErrorMessage';
import ScopeDataGrid from '../../../components/widgetLib/ScopeDataGrid';
import DrillDownHeader from './DrillDownHeader';
import DetailsList, { detailsPropTypeShape } from '../../../components/widgetLib/DetailsList';
import {
  numberColumn,
  renderCellWithTooltip,
} from "../../../utils/gridColumns";

const ProductionRecommendationLayout = ({ insight, subtitleDetails }) => {
  const { data, isLoading, isError } = useProductionRecommendationDrillDown(insight['Work Order']);
  if (isLoading) {
    return <Loader />;
  }
    
  if (isError) {
    return (
      <ErrorMessage />
    );
  }

  const columns = [
    {
      field: 'item_code',
      headerName: 'Item Code',
      flex: 2,
      renderCell: renderCellWithTooltip,
      minWidth: 180,
    },
    {
      field: 'batch',
      headerName: 'Batch',
      flex: 1.5,
      renderCell: renderCellWithTooltip,
      minWidth: 90,
    },
    {
      field: 'weight_pack',
      headerName: 'Weight Pack',
      flex: 1,
      minWidth: 90,
      ...numberColumn(3),
    },
    {
      field: 'used_quantity_by_weight_pack',
      headerName: 'Used Quantity (full drums)',
      flex: 1.2,
      minWidth: 90,
      ...numberColumn(),
    },
    {
      field: 'suggested_by_weight_pack',
      headerName: 'Quantity By Weight Pack',
      flex: 1.2,
      minWidth: 90,
      ...numberColumn(),
    },
    {
      field: 'min_brix',
      headerName: 'Min. Brix',
      flex: 1,
      minWidth: 100,
      ...numberColumn(),
    },
    {
      field: 'min_ratio',
      headerName: 'Min. Ratio',
      flex: 1,
      minWidth: 100,
      ...numberColumn(),
    },
    {
      field: 'total_used_quantity',
      headerName: 'Total Quantity',
      flex: 1,
      minWidth: 100,
      ...numberColumn(),
    },
  ];

  const totalUsedQuantity = data.length > 0 ? data.map((item) => item.total_used_quantity).reduce((a, b) => a + b, 0).toFixed(2): 'N/A';
  const totalUsedByWeightPack = data.length > 0 ? data.map((item) => item.suggested_by_weight_pack).reduce((a, b) => a + b, 0).toFixed(2): 'N/A';
  const aggregateRow = {
    batch: '',
    item_code: 'Weighted Average / Total',
    min_brix:  data.length > 0 ? data[0]['weighted_avg_brix']: 'N/A',
    min_ratio:  data.length > 0 ? data[0]['weighted_avg_ratio']: 'N/A',
    total_used_quantity: totalUsedQuantity,
    suggested_by_weight_pack: totalUsedByWeightPack,
  };

  return (
    <Stack spacing={1} sx={{ height: '100%' }}>
      <DrillDownHeader
        title={insight.title}
        subtitle={<DetailsList 
          details={subtitleDetails} 
          itemsPerRow={3}
        />}
      />
      <ScopeDataGrid
        rows={[...data, aggregateRow]}
        columns={columns}
        style={{
          width: '100%',
        }}
        exportFileName={_.snakeCase(`production_recommendation_for_contract_${insight['Contract']}`)}
        getRowId={(row) => row.batch}
        getRowClassName={(params) => params.id === '' ? 'production-recommendation-table-aggregate-row' : ''}
      />
    </Stack>
  );
};

ProductionRecommendationLayout.propTypes = {
  insight: PropTypes.object.isRequired,
  subtitleDetails: detailsPropTypeShape,
};

export default ProductionRecommendationLayout;
