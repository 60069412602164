import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Stack from "@mui/material/Stack";
import { useContractKPIs } from './hooks';
import KPI from '../../components/widgetLib/KPI';
import DemandFulfillmentGauge from '../../components/widgetLib/DemandFulfillmentGauge';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import { currencyFormatter } from "../../utils/currency";

const ContractKPIs = ({ contractId }) => {
  const { data, isLoading, isError } = useContractKPIs(contractId);

  if (isLoading) {
    return <Loader/>;
  }

  if (isError) {
    return <ErrorMessage/>;
  }

  const remainingRevenue = data.remaining_revenue.currency === 'N/A' ? 'N/A': currencyFormatter(data.remaining_revenue.currency, 2, 2, 'compact').format(data.remaining_revenue.remaining_revenue);

  return (
    <Grid container spacing={1} alignItems='stretch'>
      <Grid item xs={4}>
        <KPI
          className='contract-kpi-container'
          title="Fulfillment"
          content={
            <DemandFulfillmentGauge
              value={{
                demand: data.fulfillment.contractQuantity.totalQuantity,
                fulfillment: data.fulfillment.contractQuantity.totalQuantity - data.fulfillment.contractQuantity.openQuantityInvoicesOnly,
              }}
              size="5rem"
            />
          }
          footer={<Stack>
            <div>Open Quantity / Open Contract: {data.fulfillment.contractQuantity.openQuantityInvoicesOnly} / {data.fulfillment.contractQuantity.openQuantity}</div>
            <div>Sales Orders / Allocated Quantity: {data.fulfillment.contractOrders.futureSalesOrdersQuantity} / {data.fulfillment.contractOrders.AllocatedSalesOrdersQuantity}</div>
          </Stack>}
        />
      </Grid>
      <Grid item xs={4}>
        <KPI
          className='contract-kpi-container'
          title="Days Remaining"
          footer={`contract ends at ${data.remaining_days.final_delivery_date}`}
          content={data.remaining_days.remaining_days}
        />
      </Grid>
      <Grid item xs={4}>
        <KPI
          className='contract-kpi-container'
          title="Remaining Revenue"
          content={remainingRevenue}
        />
      </Grid>
    </Grid>
  );
};

ContractKPIs.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default ContractKPIs;
