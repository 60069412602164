import React from 'react';
import { useParams } from "react-router-dom";
import Contract from './Contract';

export const ContractPage = () => {
  const { contractId } = useParams();
  return (
    <div style={{ padding: '1rem' }}>
      <Contract contractId={contractId}/>
    </div>
  );
};
