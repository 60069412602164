import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useMRPGraph } from "./hooks";
import { 
  useSite,
  useProductFamilyAndItemCodes,
  useMRPShowOnlyError,
} from "../../state/hooks";
import ErrorMessage from "../../components/ErrorMessage";
import Loader from "../../components/Loader";
import MRPGraph from "../../components/MRPGraph";

const MRPPageGraph = ({ selectedItemCode, view, virtualContractsView }) => {
  const { site } = useSite();
  const { productFamily, itemCodes } = useProductFamilyAndItemCodes();
  const { mrpShowOnlyError } = useMRPShowOnlyError();

  const { data, isLoading, isError } = useMRPGraph(
    site,
    productFamily,
    itemCodes,
    selectedItemCode,
    view,
    mrpShowOnlyError,
    virtualContractsView,
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <Grid item container direction="row" alignItems={"center"} alignContent={"center"} style={{ justifyContent:'center' }}>
        <ErrorMessage />
      </Grid>);
  }

  return (
    <Paper style={{ width: "100%", height: "100%", padding: '0.5rem' }}>
      {selectedItemCode &&
        <Grid item className="mrp-graph-title">
          {`MRP graph for item code ${selectedItemCode}`}
        </Grid>
      }
      <Grid
        item
        className="mrp-graph-container"
        height={`${selectedItemCode ? 95 : 100}%`}
      >
        <MRPGraph data={data} yAxisLabel={selectedItemCode ? 'Stock\nCover' : 'Quantity'} view={view} />
      </Grid>
    </Paper>
  );
};

MRPPageGraph.propTypes = {
  selectedItemCode: PropTypes.string,
  view: PropTypes.oneOf(['week', 'month']),
  virtualContractsView: PropTypes.oneOf(['include', 'exclude']),
};

MRPPageGraph.defaultProps = {
  view: 'week',
  virtualContractsView: 'include',
};

export default MRPPageGraph;
