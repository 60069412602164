import { useBackendAPIGetRequest } from "../../connectors/apis";
import { convertObjectToURLString } from "../../connectors/utils";

export const useTurnover = (filters) =>
  useBackendAPIGetRequest({
    queryKey: ['turnover', filters],
    path: `revenue/turnover?${convertObjectToURLString(filters)}`,
  });

export const useMissRevenue = (filters) =>
  useBackendAPIGetRequest({
    queryKey: ['miss', filters],
    path: `revenue/miss?${convertObjectToURLString(filters)}`,
  });

export const useAnnualRevenue = (filters) =>
  useBackendAPIGetRequest({
    queryKey: ['annual', filters],
    path: `revenue/annual?${convertObjectToURLString(filters)}`,
  });

export const useNonMovingInventory = (filters) =>
  useBackendAPIGetRequest({
    queryKey: ['non_moving_inventory', filters],
    path: `revenue/non_moving_inventory?${convertObjectToURLString(filters)}`,
  });

export const useOverstock = (filters) =>
  useBackendAPIGetRequest({
    queryKey: ['overstock', filters],
    path: `revenue/overstock?${convertObjectToURLString(filters)}`,
  });

export const useInventory = (filters) =>
  useBackendAPIGetRequest({
    queryKey: ['inventory', filters],
    path: `revenue/inventory?${convertObjectToURLString(filters)}`,
  });

export const useDashboardFilterValues = (category) => useBackendAPIGetRequest({
  queryKey: ['dashboardFilterValues', category],
  path: `/revenue/filter_values/${category}`,
});


