import React, { useState } from "react";
import { CSVLink } from "react-csv";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FileDownloadOutlined from "@mui/icons-material/FileDownloadOutlined";
import Stack from "@mui/material/Stack";
import { APP_WINDOW_HEIGHT } from "../../constants";
import ExpectedMissRevenue from "./widgets/ExpectedMissRevenue";
import ExpectedOverstock from "./widgets/ExpectedOverstock";
import InventoryValue from "./widgets/InventoryValue";
import NonMovingInventory from "./widgets/NonMovingInventory";
import TurnOver from "./widgets/TurnOver";
import Revenue from "./widgets/Revenue";
import DashboardFilters from "./DashboardFilters";
import FilterChips from "../../components/FilterChips";
import eventTypes from "../../analytics/eventTypes";
import { getTodayForExport } from "../../utils/dateTime";
import { useSendAnalytics } from "../../analytics/analytics";
import './styles.scss';


const Dashboard = () => {
  const [selectedSites, setSelectedSites] = useState([]);
  const sendAnalytics = useSendAnalytics();
  const [elementHeight, setElementHeight] = React.useState(0);
  const ref = React.useRef(null);
  const dashboardCards=[
    <InventoryValue filters={{ site: selectedSites }} elementHeight={elementHeight} />,
    <ExpectedOverstock filters={{ site: selectedSites }} elementHeight={elementHeight} />,
    <ExpectedMissRevenue filters={{ site: selectedSites }} elementHeight={elementHeight} />,
    <NonMovingInventory filters={{ site: selectedSites }} elementHeight={elementHeight} />,
    <TurnOver filters={{ site: selectedSites }} elementHeight={elementHeight} />,
    <Revenue filters={{ site: selectedSites }} elementHeight={elementHeight} />,
  ];

  const csvData = [];
  const csvHeaders = [];
  const siteFilterChips = selectedSites.map((site) => ({
    label: 'site',
    value: site,
    onDelete: () => {
      sendAnalytics(eventTypes.fulfillment.fulfillmentFilterChipRemoved, { 'type': 'site', 'item': site });
      setSelectedSites(selectedSites.filter((s) => s !== site));
    },
  }));

  React.useEffect(() => {
    if (ref.current) {
      setElementHeight(ref.current.clientHeight);
    }
  }, []);

  return (
    <Grid
      container
      item
      spacing={1}
      columnSpacing={1}
      className="dashboard-page"
      height={`${APP_WINDOW_HEIGHT}vh`}
    >
      <Grid item xs={2} style={{ height: '100%' }}>
        <DashboardFilters
          selectedSites={selectedSites}
          setSelectedSites={setSelectedSites}
        />
      </Grid>
      <Grid item xs={10} style={{ height: '100%' }}>
        <Stack spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            className="dashboard-page-header"
          >
            <div className="filter-chip-area">
              <FilterChips filterChips={[...siteFilterChips]}/>
            </div>
            {csvData && csvData.length > 0 &&
                <Button
                  startIcon={<FileDownloadOutlined/>}
                  onClick={() => sendAnalytics(eventTypes.dashboard.dashboardDataExportedToCSV, {})}>
                  <CSVLink
                    data={csvData} headers={csvHeaders}
                    filename={`dashboard_data_${getTodayForExport()}`}>EXPORT</CSVLink>
                </Button>
            }
          </Stack>
        </Stack>
        <Grid item container sx={{ height: '96%', width: '100%' }} justifyContent="center" alignItems="flex-start">
          {dashboardCards.map((card, index) =>
            <Grid item ref={ref} container xs={4} sx={{ height: '40vh' }} key={`dashboard-kpi-card-${index}`}>{card}</Grid>)}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;