import { createTheme } from '@mui/material/styles';
import Palette from './styling/palette.scss';

export const ScopeLightTheme = createTheme({
  palette: {
    background: {
      default: '#fffffff',
    },
  },
});

export const ScopeDarkTheme = createTheme({
  components: {
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: Palette.DarkThemeText,
          // marginLeft: '1rem',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          // backgroundColor: DARK_BACKGROUND_SECONDARY,
          // margin: '0.25rem',
          // padding: '1rem',
          // color: 'white',
          // textAlign: 'center',
          // height: '3.5rem',
          // width: '3.5rem',
          // borderRadius: '0.5rem',
          minWidth: '2rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: `${Palette.DarkThemeTextDisabled} !important`,
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          backgroundColor: Palette.DarkThemeBackground,
          color: Palette.DarkThemeText,
          borderRadius: '3rem !important',
        },
        input: {
          "&.Mui-disabled": {
            color: `${Palette.DarkThemeTextDisabled} !important`,
            WebkitTextFillColor: `${Palette.DarkThemeTextDisabled} !important`,
          },
        },
      },
    },
    MuiToggleButton : {
      styleOverrides: {
        root: {
          background: Palette.DarkThemeBackgroundPaper,
          color: 'white',
          '&.Mui-selected': {
            background: Palette.DarkThemeBackgroundPaper,
            '&:hover': {
              background: Palette.DarkThemeBackgroundPaper,
              opacity: 0.8,
            },
          },
          '&:hover': {
            background: Palette.DarkThemeBackgroundPaper,
            opacity: 0.8,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          background: Palette.DarkThemeBackgroundPaper,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'white',
          '&.Mui-disabled': {
            opacity: 0.38,
            color: "inherit",
          },
          // margin: 0,
          // borderRadius: '0.25rem',
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          background: Palette.DarkThemeBackgroundPaper,
        },
      },
    },
    MuiPaper: {
      root: {
        background: Palette.DarkThemeBackgroundPaper,
        padding: '1rem',
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: Palette.DarkThemeBackgroundPaper,
          color: Palette.DarkThemeText,
          // borderRadius: '1.5rem',
          // fontWeight: '400',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        columnHeaders: {
          borderRadius: '0.9rem 0.9rem 0 0',
          background: Palette.DarkThemeBackgroundTableHeader,
          borderBottom: 'none',
        },
        columnSeparator: {
          visibility: 'hidden',
        },
        row: {
          borderRadius: '1rem',
          background: Palette.DarkThemeBackground,
          marginLeft: '0.5rem',
          marginRight: '0.5rem',
        },
        virtualScrollerContent: {
          background: Palette.DarkThemeBackgroundSecondary,
        },
        cell: {
          borderBottom: 'none',
          borderLeft: '1px solid',
          borderColor: Palette.DarkThemeBackgroundSecondary,
          textAlign: 'start',
        },
        footerContainer: {
          background: Palette.DarkThemeBackgroundSecondary,
          borderRadius: '0 0 0.9rem 0.9rem',
          borderTop: 'none',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderRadius: '0.9rem 0.9rem 0 0',
          background: Palette.DarkThemeBackgroundTableHeader,
          borderBottom: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          textAlign: 'start',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: Palette.DarkThemeBackground,
          color: Palette.DarkThemeText,
          opacity: '0.8',
          borderRadius: '0.8rem',
          top: '0',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          top: '8px !important',
        },
      },
    },
  },
  palette: {
    background: {
      default: Palette.DarkThemeBackground,
      paper: Palette.DarkThemeBackgroundPaper,
      secondary: Palette.DarkThemeBackgroundSecondary,
    },
    primary: {
      main: Palette.DarkThemePrimary,
    },
    cardText: {
      main: Palette.DarkThemeText,
    },
    secondary: {
      main: '#009af5',
    },
    upToTen: {
      main: '#f8696b',
    },
    upToTwenty: {
      main: '#f98370',
    },
    upToThirty: {
      main: '#fb9d75',
    },
    upToForty: {
      main: '#fcb77a',
    },
    upToFifty: {
      main: '#fdd17f',
    },
    upToSixty: {
      main: '#ffeb84',
    },
    upToSeventy: {
      main: '#e0e383',
    },
    upToEighty: {
      main: '#c1da81',
    },
    upToNinety: {
      main: '#a1d07f',
    },
    upToHundred: {
      main: '#83c77d',
    },
    hundred: {
      main: '#63be7b',
    },
    text: {
      primary: Palette.DarkThemeText,
      secondary: '#cccccc',
    },
  },
});
