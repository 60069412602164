import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import { useContractMissingSpecDetails } from './hooks';
import ScopeDataGrid from "../../components/widgetLib/ScopeDataGrid";
import { numberColumn, renderCellWithTooltip } from "../../utils/gridColumns";

const ContractMissingSpecDetails = ({ contractId }) => {
  const { data, isLoading, isError } = useContractMissingSpecDetails(contractId);

  const columns = [
    {
      field: 'customer',
      headerName: 'Customer',
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'key_account_manager',
      headerName: 'Key Account Manager',
      flex: 1,
      renderCell: renderCellWithTooltip,
    },
    {
      field: 'product_family',
      headerName: 'Product',
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'item_code',
      headerName: 'Item Code',
      flex: 1,
      renderCell: renderCellWithTooltip,
    },
    {
      field: 'min_brix',
      headerName: 'Min. Brix',
      flex: 1,
      ...numberColumn(),
    },
    {
      field: 'max_brix',
      headerName: 'Max. Brix',
      flex: 1,
      ...numberColumn(),
    },
    {
      field: 'min_ac',
      headerName: 'Min. Acidity',
      flex: 1,
      ...numberColumn(),
    },
    {
      field: 'max_ac',
      headerName: 'Max. Acidity',
      flex: 1,
      ...numberColumn(),
    },
    {
      field: 'min_ratio',
      headerName: 'Min. Ratio',
      flex: 1,
      ...numberColumn(),
    },
    {
      field: 'max_ratio',
      headerName: 'Max. Ratio',
      flex: 1,
      ...numberColumn(),
    },
  ];
    
  if (isLoading) {
    return <Loader/>;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  if (data.length > 0) {
    return (
      <>
        <Typography className="contract-section-title">
            Missing Spec Details
        </Typography>
        <ScopeDataGrid
          columns={columns}
          rows={data}
          isCellEditable={() => false}
          getRowId={(row) => row.id}
          style={{
            width: '100%',
          }}
          isAutoHeight={true}
        />
      </>
    );
  }
  return <></>;
};

ContractMissingSpecDetails.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default ContractMissingSpecDetails;
