import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack';
import MRPFilters from "./MRPFilters";
import MRPTablesList from "./MRPTablesList";
import {
  useSite,
  useProductFamilyAndItemCodes,
  useMRPView,
  useVirtualContracts,
} from '../../state/hooks';
import MRPPageGraph from './MRPPageGraph';
import WeekMonthToggle from '../../components/widgetLib/WeekMonthToggle';
import VirtualContractsToggle from "../../components/widgetLib/VirtualContractsToggle";
import { useSendAnalytics } from '../../analytics/analytics';
import eventTypes from '../../analytics/eventTypes';
import { APP_WINDOW_HEIGHT } from "../../constants";
import "./styles.scss";

const MRP = () => {
  const { site } = useSite();
  const { productFamily, itemCodes } = useProductFamilyAndItemCodes();
  const sendAnalytics = useSendAnalytics();
  const [, setSearchParams] = useSearchParams();
  const [selectedItemCode, setSelectedItemCode] = useState("");
  const { mrpView, setMrpView } = useMRPView();
  const { virtualContractsView, setVirtualContractsView } = useVirtualContracts();

  const productFamilyLength = productFamily.length;
  const itemCodesLength = productFamily.length;
  const productFamilyStr = JSON.stringify(productFamily);
  const itemCodesStr = JSON.stringify(itemCodes);
  const selectedItemCodeToSet = (itemCodesLength === 1 && productFamilyLength === 0) ? itemCodes[0] : '';

  useEffect(() => {
    const newParams = {};
    if (site){
      newParams.site = site;
    }
    if (productFamilyLength){
      newParams.product_family = productFamilyStr;
    }
    if (itemCodesLength){
      newParams.item_codes = itemCodesStr;
    }
    if (site || productFamilyLength || itemCodesLength){
      setSearchParams(newParams);
    }
    setSelectedItemCode(selectedItemCodeToSet);
  }, [site, productFamilyStr, itemCodesStr, productFamilyLength, itemCodesLength, selectedItemCodeToSet]);

  const handleWeekMonthToggle = (_, newView) => {
    if (newView) {
      sendAnalytics(eventTypes.mrp.mrpWeekMonthToggle, { view: newView });
      setMrpView(newView);
    }
  };

  const handleVirtualContractsToggle = (_, newView) => {
    if (newView) {
      sendAnalytics(eventTypes.mrp.mrpVirtualContractsToggle, { view: newView });
      setVirtualContractsView(newView);
    }
  };

  return (
    <Grid
      container
      item
      spacing={1}
      columnSpacing={1}
      style={{ padding: "1rem" }}
      height={`${APP_WINDOW_HEIGHT}vh`}
      className="mrp-page"
    >
      <Grid
        container
        item
        xs={3}
        xl={2}
        height="100%"
        wrap="nowrap"
        style={{ overflowY: "auto" }}
      >
        <MRPFilters />
      </Grid>
      <Grid
        container
        item
        xs={9}
        xl={10}
        direction={"row"}
        spacing={1}
        height="100%"
        justifyContent="center"
        alignItems="flex-start"
      >
        {!!site ? (
          <React.Fragment>
            <Stack 
              alignItems={"center"} 
              alignContent={"center"}
              height={{ xs: '50%', lg: '30%' }}
              style={{
                justifyContent:'center',
                width: '100%',
                padding: '0.8rem 0.5rem',
                minHeight: '250px',
              }} 
              spacing={1}
            >
              <Stack direction="row" style={{ height: '2rem', width: '100%' }} justifyContent="flex-end" spacing={1}>
                <VirtualContractsToggle 
                  handleToggle={handleVirtualContractsToggle}
                  selectedValue={virtualContractsView}
                />
                <WeekMonthToggle
                  view={mrpView}
                  handleToggleView={handleWeekMonthToggle}
                />
              </Stack>
              <div style={{ height: 'calc(100% - 2rem)', width: '100%' }}>
                <MRPPageGraph selectedItemCode={selectedItemCode} view={mrpView} virtualContractsView={virtualContractsView} />
              </div>
            </Stack>
            <Grid 
              item 
              container 
              direction={"row"} 
              alignItems={"center"}
              alignContent={"center"}
              height={{ xs: '50%', lg: '70%' }} 
              style={{ justifyContent:'center' }}
            >
              <MRPTablesList
                selectedItemCode={selectedItemCode}
                setSelectedItemCode={setSelectedItemCode}
                view={mrpView}
                virtualContractsView={virtualContractsView}
              />
            </Grid>
          </React.Fragment>
        ) : (
          <div>SELECT A SITE</div>
        )}
      </Grid>
    </Grid>
  );
};

export default MRP;
