const eventTypes = {
  insights: {
    insightTypeClicked: 'insight_type_clicked',
    insightTableRowClicked: 'insight_table_row_clicked',
  },
  mrp: {
    mrpItemCodeSelected: 'mrp_item_code_selected',
    mrpItemCodeUnselected: 'mrp_item_code_unselected',
    mrpDemandCellClicked: 'mrp_demand_cell_clicked',
    mrpIncomingCellClicked: 'mrp_incoming_cell_clicked',
    mrpWeekMonthToggle: 'mrp_week_month_toggle',
    mrpVirtualContractsToggle: 'mrp_virtual_contracts_toggle',
    mrpFilterChipRemoved: 'mrp_filter_chip_removed',
    mrpFilterAction: 'mrp_filter_action',
    mrpTablesExportedToCSV: 'mrp_tables_exported_to_csv',
    mrpShowOnlyErrorToggle: 'mrp_show_only_error_toggle',
    mrpTablesSort: 'mrp_tables_sort',
  },
  contract: {
    contractDrawerOpened: 'contract_drawer_opened',
    contractOrdersGraphModeToggle: 'contract_orders_graph_mode_toggle',
  },
  fulfillment: {
    fulfillmentWeekMonthToggle: 'fulfillment_week_month_toggle',
    fulfillmentModeToggle: 'fulfillment_mode_toggle',
    fulfillmentVirtualContractsToggle: 'fulfillment_virtual_contracts_toggle',
    fulfillmentFilterChipRemoved: 'fulfillment_filter_chip_removed',
  },
  dashboard: {
    dashboardDataExportedToCSV: 'dashboard_data_exported_to_csv',
  },
  global: {
    drawerClose: 'drawer_closed',
    drawerOpen: 'drawer_open',
    tableExportedToCSV: 'table_exported_to_csv',
    sessionStart: 'session_start',
    navigation: 'navigation',
  },
};

export default eventTypes;
