import { useBackendAPIGetRequest } from '../../connectors/apis';
import { addFiltersToPath } from '../../connectors/utils';

export const useMRPTables = (site, productFamilies, itemCodes, selectedItemCode, view = 'week', showOnlyError = false, virtualContractsView='include') => {
  let path = addFiltersToPath('/mrp/tables', site, productFamilies, itemCodes);
  if (selectedItemCode) {
    path += `&item_code=${encodeURIComponent(selectedItemCode)}`;
  }
  path += `&view=${view}&show_only_error=${showOnlyError}&virtual_contracts_view=${virtualContractsView}`;
  return useBackendAPIGetRequest({
    queryKey: ['mrp_tables', path],
    path,
    enabled: !!site,
  });
};

export const useMRPGraph = (site, productFamilies, itemCodes, selectedItemCode, view = 'week', showOnlyError = false, virtualContractsView='include') => {
  let path = addFiltersToPath('/mrp/graph', site, productFamilies, itemCodes);
  if (selectedItemCode) {
    path += `&item_code=${encodeURIComponent(selectedItemCode)}`;
  }
  path += `&view=${view}&show_only_error=${showOnlyError}&virtual_contracts_view=${virtualContractsView}`;
  return useBackendAPIGetRequest({
    queryKey: ['mrp_graph', path],
    path,
    enabled: !!site,
  });
};

export const useMRPFilterValues = (filterCategory) => useBackendAPIGetRequest({
  queryKey: ['mrp_filter_values', filterCategory],
  path: `/mrp/filter_values/${filterCategory}`,
});


export const useNestedMRPFilterValues = () => useBackendAPIGetRequest({
  queryKey: ['nested_mrp_filter_values'],
  path: 'mrp/nested_filter_values',
});

export const useIncomingInfo = (itemCode, site, view, selectedTime) => {
  const encodedSite = encodeURIComponent(site);
  return useBackendAPIGetRequest({
    queryKey: ['mrp_incoming_info', itemCode, encodedSite, view, selectedTime],
    path: `/mrp/incoming_info/${encodeURIComponent(itemCode)}/${view}/${selectedTime}?site=${encodedSite}`,
  });
};

