import React from "react";
import PropTypes from "prop-types";

import KPIWithGraph from "../../../components/widgetLib/KpiWithGraph";
import { useAnnualRevenue } from "../hooks";
import Loader from "../../../components/Loader";
import ErrorMessage from "../../../components/ErrorMessage";
import { kpiColor } from "./constants";
import StackedBaGraph from "../../../components/widgetLib/StackedBaGraph";

const Revenue = ({ filters, elementHeight }) => {
  const { data, isLoading, isError } = useAnnualRevenue(filters);

  if (isLoading) {
    return <Loader/>;
  }
  if (isError) {
    return <ErrorMessage />;
  }
  
  const color = kpiColor['revenue'][data.kpiDirection];
  const graphHeight = elementHeight > 300 ? elementHeight/1.5 : elementHeight/2;
  return (
    <KPIWithGraph
      title={'Revenue'}
      value={data.value}
      unit={'USD'}
      isCurrency={true}
      className={'dashboard-kpi'}
      kpiPercent={data.kpiPercent.replace('-', '').replace('+', '')}
      color={color}
      kpiDirection={data.kpiDirection}
      content={<StackedBaGraph data={data.graphData} groupAKey={'fulfilled_cost'} groupBKey={'unfulfilled_cost'} elementHeight={graphHeight}/>}
    />
  );
};

Revenue.propTypes = {
  filters: PropTypes.object.isRequired,
  elementHeight: PropTypes.number,
};

export default Revenue;