import React from "react";
import PropTypes from "prop-types";
import { 
  format,
  differenceInCalendarDays,
  add,
} from 'date-fns';
import {
  AreaChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Palette from '../styling/palette.scss';

const OrdersOverTimeGraph = ({ data, mode }) => {
  if (!data){
    return <div/>;
  }

  const contractDays = differenceInCalendarDays(new Date(data.end), new Date(data.start));

  const yAxisDomainMax = mode === 'fulfillmentRate' 
    ? data.total_quantity
    : Math.max(...data.points.map((item) => item.quantity)) * 1.25;
  
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data.points}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#08E0EA" stopOpacity={0.8}/>
            <stop offset="86.46%" stopColor="rgba(103, 139, 253, 0)" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="days_from_start"
          type="number"
          domain={[0, contractDays]}
          padding={{ left: 40, right: 30 }}
          tick={{ fill: Palette.DarkThemeText, fontSize: '0.8rem' }}
          angle={30}
          ticks={[0, ...data.points.map((point) => point['days_from_start']), contractDays]}
          interval={1}
          tickFormatter={(value) => format(add(new Date(data.start), { days: value }), 'yyyy-MM-dd')}
        />
        <YAxis
          domain={[0, yAxisDomainMax]}
          tick={{ fill: Palette.DarkThemeText }}
          tickFormatter={(value) => Number(value.toFixed(2))}
        />
        <Tooltip 
          contentStyle={{ background: Palette.DarkThemeBackgroundPaper, borderRadius: '2rem' }} 
          wrapperStyle={{ borderRadius: '2rem' }} 
          labelFormatter={(value) => format(add(new Date(data.start), { days: value }), 'yyyy-MM-dd')}
          formatter={(value, name, props) => [props.payload.quantity.toFixed(2), "Quantity"]}
        />
        <Area
          type="monotoneX"
          fillOpacity={1}
          fill="url(#colorUv)"
          dataKey={mode === 'orders' ? 'quantity' : 'quantity_cumsum'}
          stroke="#08E0EA"
          dot={{ stroke: 'white', r: 4 }}
          activeDot={{ stroke: Palette.DarkThemePrimary, r: 6 }}
        />
        <Line
          type="linear"
          dataKey={mode === 'orders' ? 'quantity' : 'quantity_cumsum'}
          stroke={Palette.DarkThemePrimary}
          dot={false}
          strokeWidth={2}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

OrdersOverTimeGraph.propTypes = {
  data: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
};

OrdersOverTimeGraph.defaultProps = {
  mode: 'orders',
};

export default OrdersOverTimeGraph;
