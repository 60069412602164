import React from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useSendAnalytics } from "../../analytics/analytics";
import eventTypes from "../../analytics/eventTypes";
import "./styles.scss";


const ScopeDrawer = ({ onClose, className, content, anchor }) => {
  const sendAnalytics = useSendAnalytics();
  sendAnalytics(eventTypes.global.drawerOpen, { type: className });
  const isRightAnchor = anchor === 'right';
  const onGlobalClose = () => {
    sendAnalytics(eventTypes.global.drawerClose, { type: className });
    onClose();
  };
  return (
    <Drawer
      open
      anchor={anchor}
      PaperProps={{ className: className }}
      onClose={onGlobalClose}
    >
      <Stack direction="row" spacing={1} justifyContent={isRightAnchor ? 'flex-start' : 'flex-end'}>
        <Box
          className="scope-drawer-header"
          alignItems="center"
        >
          <IconButton onClick={onGlobalClose}>
            {isRightAnchor ? <KeyboardDoubleArrowLeftRoundedIcon/> : <KeyboardDoubleArrowRightRoundedIcon/>}
          </IconButton>
        </Box>
      </Stack>
      {content}
    </Drawer>);
};

ScopeDrawer.defaultProps = {
  anchor: 'right',
};

ScopeDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  content: PropTypes.element.isRequired,
  anchor: PropTypes.string.isRequired,
};

export default ScopeDrawer;
