import React from 'react';
import PropTypes from 'prop-types';
import { useContractDetails } from './hooks';
import Loader from '../../components/Loader';
import ScopeDataGrid from '../../components/widgetLib/ScopeDataGrid';
import ErrorMessage from '../../components/ErrorMessage';
import {
  currencyColumn,
  dateColumn,
  numberColumn,
  renderCellWithTooltip,
} from "../../utils/gridColumns";

const ContractDetails = ({ contractId }) => {
  const { data, isLoading, isError } = useContractDetails(contractId);

  if (isLoading) {
    return <Loader/>;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  const columns = [
    {
      field: 'ItemCode',
      headerName: 'Item Code',
      minWidth: 140,
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'site',
      headerName: 'Site',
      minWidth: 80,
      flex: 0.7,
    },
    {
      field: 'CardName',
      headerName: 'Customer',
      minWidth: 140,
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'sub_sub_family',
      headerName: 'Product Family',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'Quantity',
      headerName: 'Total Quantity',
      minWidth: 100,
      flex: 1,
      ...numberColumn(),
    },
    {
      field: 'pricePerUnit',
      headerName: 'Price',
      minWidth: 80,
      flex: 1,
      valueGetter: (params) => params.row.Price === 'N/A' ? { value: -1, currency: 'USD' }: { value: Number(params.row.Price), currency: params.row.Currency },
      ...currencyColumn(0, 0),
    },
    {
      field: 'totalPrice',
      headerName: 'Total Price',
      minWidth: 90,
      flex: 1,
      valueGetter: (params) => params.row.Price === 'N/A' ? { value: -1, currency: 'USD' }: { value: Number(params.row.Price * params.row.Quantity), currency: params.row.Currency },
      ...currencyColumn(2, 2, 'compact'),
    },
    {
      field: 'pack_type',
      headerName: 'Pack Type',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'brix_range',
      headerName: 'Brix',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'ac_range',
      headerName: 'Acidity',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'ratio_range',
      headerName: 'Ratio',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'start_delivery_date',
      headerName: 'Delivery Start Date',
      minWidth: 120,
      flex: 1,
      ...dateColumn(),
    },
    {
      field: 'final_delivery_date',
      headerName: 'Final Delivery Date',
      minWidth: 120,
      flex: 1,
      ...dateColumn(),
    },
  ];

  return (
    <ScopeDataGrid
      rows={[data]}
      columns={columns}
      isCellEditable={() => false}
      disableColumnSelector
      disableColumnMenu
      disableColumnFilter
      disableColumnSort
      isAutoHeight={true}
      style={{
        width: `100%`,
      }}
      getRowId={(row) => row.DocNum}
    />
  );
};

ContractDetails.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default ContractDetails;
