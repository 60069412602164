import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import PropTypes from "prop-types";
import React from "react";
import Palette from "../../styling/palette.scss";

const LineGraph = ({ data, elementHeight }) => {
  if (!data){
    return <div/>;
  }
  return (
    <ResponsiveContainer width="100%" height={elementHeight}>
      <LineChart
        width={500}
        height={elementHeight}
        data={data}
        margin={{
          top: 0,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.2} vertical={false} />
        <XAxis dataKey="month" tick={{ fill: Palette.DarkThemeTextDisabled, fontSize: '0.8rem' }} angle={-45}/>
        <YAxis tick={{ fill: Palette.DarkThemeTextDisabled, fontSize: '0.8rem' }} domain={['auto', 'auto']} />
        <Tooltip
          contentStyle={{ background: Palette.DarkThemeBackgroundPaper, borderRadius: '2rem', fontSize: '0.8rem' }}
          wrapperStyle={{ borderRadius: '2rem' }}
        />
        <Line
          type="monotone"
          dataKey="value"
          stroke={Palette.DarkThemeColorCodePurple}
          strokeWidth={2}
          dot={{ stroke: Palette.DarkThemePrimary, fill: Palette.DarkThemePrimary, strokeWidth: 2 }}
          activeDot={{ r: 5 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

LineGraph.propTypes = {
  data: PropTypes.array.isRequired,
  elementHeight: PropTypes.number,
};

export default LineGraph;
