import React from 'react';
import PropTypes from 'prop-types';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { useFulfillment } from './hooks';
import ContractedDemandTable from '../../components/ContractedDemandTable';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import { getTodayForExport } from "../../utils/dateTime";

const FulfillmentTable = ({ view, mode, filters, virtualContractsView, elementHeight }) => {
  const { data, isLoading, isError } = useFulfillment(view, mode, filters, virtualContractsView);

  if (isLoading) {
    return <Loader/>;
  }

  if (isError) {
    return <ErrorMessage/>;
  }

  const exportFileName = `contract_fulfillment_${getTodayForExport()}`;

  const customToolbar = () => (
    <GridToolbarContainer sx={{ justifyContent: 'end' }}>
      <GridToolbarExport 
        csvOptions={{ fileName: exportFileName, utf8WithBom: true }}
        sx={{ backgroundColor: 'background.secondary' }} 
      />
      <GridToolbarColumnsButton/>
    </GridToolbarContainer>
  );
  
  return (
    <ContractedDemandTable
      data={data}
      view={view}
      hideFooter={data.length <= 10}
      components={{
        Toolbar: customToolbar,
      }}
      elementHeight={elementHeight}
    />
  );
};

FulfillmentTable.propTypes = {
  view: PropTypes.oneOf(['week', 'month']),
  mode: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  virtualContractsView: PropTypes.oneOf(['include', 'exclude']),
  elementHeight: PropTypes.number,
};

FulfillmentTable.defaultProps = {
  virtualContractsView: 'include',
};

export default FulfillmentTable;
