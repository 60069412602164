import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ContractDetails from './ContractDetails';
import ContractOrders from './ContractOrders';
import ContractFulfillment from './ContractFulfillment';
import ContractKPIs from './ContractKPIs';
import ContractPONextMonth from "./ContractPONextMonth";
import ContractMissingSpecDetails from "./ContractMissingSpecDetails";
import ContractRatioOutOfSpec from "./ContractRatioOutOfSpec";
import './styles.scss';

const Contract = ({ contractId }) => (
  <Stack spacing={2} alignItems="flex-start" className="contract">
    <Typography className="contract-section-title">
      {`Contract ${contractId} Details`}
    </Typography>
    <ContractDetails contractId={contractId} />
    <ContractKPIs contractId={contractId}/>
    <Typography className="contract-section-title">
        Orders
    </Typography>
    <ContractOrders contractId={contractId} />
    <Typography className="contract-section-title">
        Fulfillment
    </Typography>
    <ContractFulfillment contractId={contractId} />
    <ContractPONextMonth contractId={contractId} />
    <ContractMissingSpecDetails contractId={contractId} />
    <ContractRatioOutOfSpec contractId={contractId} />
  </Stack>
);

Contract.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default Contract;
