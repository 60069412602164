import create from "zustand";
import produce from "immer";

const useStore = create((set) => ({
  editedDemand: {},
  removeEditedDemand: () => set(() => ({ editedDemand: {} })),
  editedDemandUpdater: (site, itemCode) => (newValue) => {
    set(
      produce((state) => {
        if (!state.editedDemand[site]) {
          state.editedDemand[site] = {};
        }
        state.editedDemand[site][itemCode] = newValue;
      })
    );
  },
  site: new URLSearchParams(window.location.search).get('site') || '',
  setSite: (site) => set({ site }),
  productFamily: JSON.parse(new URLSearchParams(window.location.search).get('product_family')) || [],
  itemCodes: JSON.parse(new URLSearchParams(window.location.search).get('item_codes')) || [],
  setProductFamilyAndItemCodes: ({ productFamily, itemCodes }) => set({ productFamily, itemCodes }),
  setProductFamily: (productFamily) => set({ productFamily }),
  setItemCodes: (itemCodes) => set({ itemCodes }),
  selectedContract: null,
  setSelectedContract: (selectedContract) => set({ selectedContract }),
  serviceWorkerRegistration: null,
  setServiceWorkerRegistration: (reg) => set({ serviceWorkerRegistration: reg }),
  drilldownItemCode: null,
  setDrilldownItemCode: (drilldownItemCode) => set({ drilldownItemCode }),
  drilldownCurrentColumn: null,
  setDrilldownCurrentColumn: (drilldownCurrentColumn) => set({ drilldownCurrentColumn }),
  drilldownCurrentColumnName: null,
  setDrilldownCurrentColumnName: (drilldownCurrentColumnName) => set({ drilldownCurrentColumnName }),
  drilldownType: null,
  setDrilldownType: (drilldownType) => set({ drilldownType }),
  mrpView: 'week',
  setMrpView: (mrpView) => set({ mrpView }),
  mrpShowOnlyError: false,
  setMrpShowOnlyError: (mrpShowOnlyError) => set({ mrpShowOnlyError }),
  mrpTableSort: '',
  setMrpTableSort: (mrpTableSort) => set({ mrpTableSort }),
  virtualContractsView: 'include',
  setVirtualContractsView: (virtualContractsView) => set({ virtualContractsView }),
}));

export default useStore;
