import React from "react";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";

const Setting = ({ settingInput, settingTitle }) => (
  <ListItem className="setting">
    <Grid container>
      <Grid item xs={4}>
        <ListItemText primary={settingTitle} />
      </Grid>
      <Grid item xs={8} className="setting-input">
        {settingInput}
      </Grid>
    </Grid>
  </ListItem>
);

Setting.propTypes = {
  settingInput: PropTypes.object.isRequired,
  settingTitle: PropTypes.string.isRequired,
};

export default Setting;
