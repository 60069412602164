import React from 'react';
import ReactDOM from 'react-dom/client';
import { 
  Navigate, 
  createBrowserRouter, 
  RouterProvider, 
} from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Auth0Provider } from "@auth0/auth0-react";
import { LocalizationProvider } from '@mui/x-date-pickers';
import App from './App';
import Dashboard from "./containers/Dashboard/Dashboard";
import MRP from './containers/MRP/MRP';
import InsightsPage from './containers/insights/InsightsPage';
import Settings from './containers/Settings/Settings';
import SettingsContainer from './containers/Settings/SettingsContainer';
import { ContractPage } from './containers/Contract/ContractPage';
import Fulfillment from './containers/Fulfillment/Fulfillment';
import ErrorMessage from './components/ErrorMessage';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import RedirectAfterLogin from './routes/redirectAfterLogin';
import config from './config';
import { AUTH0_AUTHORIZATION_SCOPE } from './constants';
import { initAnalytics } from './analytics/analytics';
import useStore from './state/useStore';
import './index.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorMessage/>,
    children: [
      {
        path: '/',
        element: <Navigate to="/insights" />,
      },
      {
        path: "/redirect",
        element: <RedirectAfterLogin />,
      },
      {
        path: "/mrp",
        element: <MRP />,
        errorElement: <ErrorMessage/>,
      },
      {
        path: '/insights',
        element: <InsightsPage />,
        errorElement: <ErrorMessage/>,
      },
      {
        path: '/settings',
        element: <Settings />,
        errorElement: <ErrorMessage/>,
        children: [
          {
            path: '/settings/:category',
            element: <SettingsContainer />,
          },
        ],
      },
      {
        path: '/contract/:contractId',
        element: <ContractPage/>,
        errorElement: <ErrorMessage/>,
      },
      {
        path: '/fulfillment',
        element: <Fulfillment/>,
        errorElement: <ErrorMessage/>,
      },
      {
        path: '/dashboard',
        element: <Dashboard/>,
        errorElement: <ErrorMessage/>,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.REACT_APP_DOMAIN}
      clientId={config.REACT_APP_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={config.REACT_APP_AUDIENCE}
      scope={AUTH0_AUTHORIZATION_SCOPE}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </Auth0Provider>
  </React.StrictMode>
);

initAnalytics();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(
  {
    onSuccess: () => console.log('Service worker registered'),
    onUpdate: (reg) => {
      useStore.setState({ serviceWorkerRegistration: reg });
    },
  }
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
