import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import ContractDetails from '../../Contract/ContractDetails';
import ContractOrders from '../../Contract/ContractOrders';
import DrillDownHeader from './DrillDownHeader';
import DetailsList, { detailsPropTypeShape } from '../../../components/widgetLib/DetailsList';

const ContractLayout = ({ insight, subtitleDetails }) => (
  <Stack spacing={2}>
    <DrillDownHeader
      title={insight.title}
      subtitle={<DetailsList
        details={subtitleDetails}
        itemsPerRow={3}
        labelGridColSpan={6}
        valueGridColSpan={6}
      />}
    />
    <ContractDetails contractId={insight['Contract']}/>
    <ContractOrders contractId={insight['Contract']}/>
  </Stack>
);

ContractLayout.propTypes = {
  insight: PropTypes.object.isRequired,
  subtitleDetails: detailsPropTypeShape,
};

export default ContractLayout;
