import _ from "lodash";
import React from 'react';
import PropTypes from 'prop-types';
import { useInsightsByType } from './hooks';
import { hasDrilldown } from './InsightDrillDown';
import ScopeDataGrid from "../../components/widgetLib/ScopeDataGrid";
import Loader from '../../components/Loader';
import ErrorMessage from "../../components/ErrorMessage";
import { useSendAnalytics } from '../../analytics/analytics';
import eventTypes from '../../analytics/eventTypes';
import { getTodayForExport } from "../../utils/dateTime";
import {
  currencyColumn,
  dateColumn,
  numberColumn,
  percentColumn,
  periodColumn,
} from "../../utils/gridColumns";
import {
  GRID_COL_TYPE_CURRENCY,
  GRID_COL_TYPE_DATE,
  GRID_COL_TYPE_NUMBER,
  GRID_COL_TYPE_PERCENT,
  GRID_COL_TYPE_TIME_PERIOD,
} from "../../constants";


const InsightTable = ({ insightType, openDrillDownDrawer }) => {
  const sendAnalytics = useSendAnalytics();

  const { data, isLoading, isError } = useInsightsByType(insightType);

  if (isLoading) {
    return <Loader/>;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  const { insights, drill_down_columns, column_types: columnToType } = data;

  const insightsColumns = Object.keys(columnToType).map((col) => {
    const columnType = columnToType[col];
    let gridCol = {
      field: col,
      headerName: col,
      minWidth: 150,
      flex: 1,
      headerClassName: 'insights-table-header',
    };
    switch (columnType) {
      case GRID_COL_TYPE_CURRENCY:
        return {
          ...gridCol,
          valueGetter: ({ value }) => value === 'N/A' ? { value: -1, currency: 'USD' }: { value: Number(value.split(' ')[0]), currency: value.split(' ')[1] },
          ...currencyColumn(0,0),
        };
      case GRID_COL_TYPE_PERCENT:
        return {
          ...gridCol,
          ...percentColumn(),
        };
      case GRID_COL_TYPE_NUMBER:
        return {
          ...gridCol,
          ...numberColumn(),
        };
      case GRID_COL_TYPE_DATE:
        return {
          ...gridCol,
          ...dateColumn(),
        };
      case GRID_COL_TYPE_TIME_PERIOD:
        return {
          ...gridCol,
          ...periodColumn(),
        };
      default:
        return {
          ...gridCol,
        };
    }
  });

  const handleRowClick = (params) => {
    if (hasDrilldown(insightType.type, insightType.source)){
      openDrillDownDrawer({
        ...params.row,
        ..._.omit(insightType, ['id']),
        column_types: columnToType,
        drill_down_columns,
      });
    }
    sendAnalytics(eventTypes.insights.insightTableRowClicked, { insight_id: params.id });
  };

  if (insightsColumns) {
    return (
      <ScopeDataGrid
        rows={insights}
        columns={insightsColumns}
        className="insights-table"
        getRowId={(row) => row.id}
        onRowClick={handleRowClick}
        exportFileName={`${_.snakeCase(insightType.type)}_${getTodayForExport()}`}
        getRowClassName={() => hasDrilldown(insightType.type, insightType.source) ? 'insights-table-row-clickable': ''}
        initialState={{
          sorting: {
            sortModel: [{ field: 'sub_sub_family', sort: 'asc' }],
          },
        }}
        dataGridTitle={ insightType.subtitle }
        shouldHideFooter={false}
        rowsPerPageOptions={[5, 10, 20, 100]}
      />
    );
  }
};

InsightTable.propTypes = {
  insightType: PropTypes.object.isRequired,
  openDrillDownDrawer: PropTypes.func.isRequired,
};

export default InsightTable;
