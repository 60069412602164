import React from "react";
import { Outlet } from "react-router-dom";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";

import SettingsSideMenuItem from "./SettingsSideMenuItem";
import { 
  SettingsAppearanceIcon,
  SettingsMachineIcon,
  SettingsSpecIcon,
  SettingsSupplierIcon, 
} from "../ScopeIcons";
import './styles.scss';


const settingsMenuItems = [
  {
    title: "Spec",
    url: "./spec",
    icon: <SettingsSpecIcon />,
  },
  {
    title: "Supplier",
    url: "./supplier",
    icon: <SettingsSupplierIcon />,
  },
  {
    title: "Machine",
    url: "./machine",
    icon: <SettingsMachineIcon />,
  },
  {
    title: "Appearance",
    url: "./appearance",
    icon: <SettingsAppearanceIcon sx={{ fill: 'none' }} />,
  },
];

const disabledMenuItems = ['Supplier', 'Machine']; // TODO: remove this once "machine" and "supplier" are implemented

const Settings = () => (
  <Grid container item className="settings-screen" spacing={0}>
    <Grid item xs={2} xl={1} className="settings-screen-sidebar" sx={{ backgroundColor: 'background.secondary' }}>
      <List className="settings-screen-side-menu">
        {settingsMenuItems.map((item) => (
          <SettingsSideMenuItem
            key={item.url} menuItem={item}
            disabled={disabledMenuItems.includes(item.title)}/>
        ))}
      </List>
    </Grid>
    <Grid item xs>
      <Outlet/>
    </Grid>
  </Grid>
);

export default Settings;
