import React from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import { 
  useMRPGraph,
  useMRPTables,
} from "../../MRP/hooks";
import {
  useSite,
} from "../../../state/hooks";
import ErrorMessage from "../../../components/ErrorMessage";
import Loader from "../../../components/Loader";
import MRPColorLegend from "../../../components/MRPColorLegend";
import MRPGraph from "../../../components/MRPGraph";
import MRPTable from "../../../components/MRPTable";
import DrillDownHeader from './DrillDownHeader';
import DetailsList, { detailsPropTypeShape } from '../../../components/widgetLib/DetailsList';

const MRPLayout = ({ insight, subtitleDetails }) => {
  
  const { data: tableData, isLoading: isLoadingTable, isError: isTableError } = useMRPTables(insight['Site'], [], [], insight['Item Code']);
  const { data: graphData, isLoading: isLoadingGraph, isError: isGraphError } = useMRPGraph(insight['Site'], [], [], insight['Item Code'] );
  const { setSite } = useSite();
  setSite(insight['Site']);
  if (isLoadingTable || isLoadingGraph) {
    return <Loader />;
  }
    
  if (isGraphError || isTableError) {
    return (
      <ErrorMessage />
    );
  }

  const { specs, data_columns } = tableData;

  return (
    <Stack spacing={2}>
      <DrillDownHeader
        title={insight.title}
        subtitle={<DetailsList
          details={subtitleDetails}
          itemsPerRow={3}
          labelGridColSpan={6}
          valueGridColSpan={6}
        />}
      />
      <div style={{ width: "100%", height: "30vh", padding: '0.5rem' }}>
        <MRPGraph data={graphData} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <MRPColorLegend />
      </div>
      <div style={{ width: "100%", padding: '0.5rem', overflowX: 'auto', height: '100%', minHeight: '200px' }}>
        <MRPTable data={specs[insight['Item Code']]} dataColumns={data_columns} itemCode={insight['Item Code']}/>
      </div>
    </Stack>
  );
};

MRPLayout.propTypes = {
  insight: PropTypes.object.isRequired,
  subtitleDetails: detailsPropTypeShape,
};

export default MRPLayout;
