import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { REDIRECT_AFTER_LOGIN_PATH_KEY } from '../constants';

const RedirectAfterLogin = () => {
  const navigate = useNavigate();
  const path = JSON.parse(localStorage.getItem(REDIRECT_AFTER_LOGIN_PATH_KEY));
  useEffect(() => {
    if (path && navigate){
      navigate(path);
      if (path.search){
        navigate(0); // refreshes the page and causes the search params to apply
      }
    }
  }, [path, navigate]);

  return <div/>; // this never actually gets rendered
};

export default RedirectAfterLogin;
