import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const DemandFulfillmentGauge = ({ value, size }) => {
  const { demand, fulfillment } = value;
  const fulfillmentPercentage = Number(((fulfillment / demand) * 100).toFixed(0));
  const fulfillmentFixed = fulfillment.toFixed(0) === '-0' ? '0': fulfillment.toFixed(0);
  const demandFixed = demand.toFixed(0) === '-0' ? '0': demand.toFixed(0);
  const ratioText = `${fulfillmentFixed}/${demandFixed}`;
  const getColor = (percent) => {
    switch (true) {
      case percent < 10:
        return 'upToTen';
      case percent < 20:
        return 'upToTwenty';
      case percent < 30:
        return 'upToThirty';
      case percent < 40:
        return 'upToForty';
      case percent < 50:
        return 'upToFifty';
      case percent < 60:
        return 'upToSixty';
      case percent < 70:
        return 'upToSeventy';
      case percent < 80:
        return 'upToEighty';
      case percent < 90:
        return 'upToNinety';
      case percent < 100:
        return 'upToHundred';
      case percent === 100:
        return 'hundred';
      default:
        return "primary";
    }  
  };
  const gaugeColor = getColor(fulfillmentPercentage);
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="center"
      className="demand-fulfillment-gauge"
    >
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress 
          variant="determinate"
          value={fulfillmentPercentage}
          size={size}
          color={gaugeColor}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            className="demand-fulfillment-gauge-text"
          >
            {`${fulfillmentPercentage}%`}
          </Typography>
        </Box>
      </Box>
      <span className="demand-fulfillment-gauge-ratio-text">
        {ratioText}
      </span>
    </Stack>
  );
};

DemandFulfillmentGauge.defaultProps = {
  size: 40, // That's the default in MaterialUI - https://mui.com/material-ui/api/circular-progress
};

DemandFulfillmentGauge.propTypes = {
  value: PropTypes.shape({
    demand: PropTypes.number,
    fulfillment: PropTypes.number,
  }).isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default DemandFulfillmentGauge;
