import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import UserMenuButton from '../components/UserMenuButton';

const AppHeader = () => (
  <Box sx={{ flexGrow: 1 }} style={{ height: "100%" }}>
    <AppBar position="fixed">
      <Toolbar className="app-header-toolbar">
        <Link href="/">
          <Box
            component="img"
            sx={{ height: 20 }}
            alt="Trellis"
            src={'/trellis-logo-white.png'}
          />
        </Link>
        <UserMenuButton/>
      </Toolbar>
    </AppBar>
    <Toolbar />
  </Box>
);

export default AppHeader;
