import React, { useState } from "react";
import SiteSelectionList from "../../components/SiteSelectionList";
import NestedFilterList from "../../components/nestedFilterList/NestedFilterList";
import FiltersList from '../../components/FiltersList';
import ErrorMessage from "../../components/ErrorMessage";
import Loader from '../../components/Loader';
import { 
  useMRPFilterValues,
  useNestedMRPFilterValues,
} from "./hooks";
import {
  useSite,
  useItemCodes,
} from '../../state/hooks';


const MRPFilters = () => {
  const { site, setSite } = useSite();
  const { itemCodes, setItemCodes } = useItemCodes();

  const [expanded, setExpanded] = useState({ "site": true, "product_family": true });

  const {
    data: sites,
    isLoading: isLoadingSites,
    isError: isErrorSites,
  } = useMRPFilterValues("site");


  const {
    data: nestedFiltersValues,
    isLoading: isLoadingNestedFiltersValues,
    isError: isErrorNestedFiltersValues,
  } = useNestedMRPFilterValues();

  if (isLoadingSites  || isLoadingNestedFiltersValues) {
    return <Loader/>;
  }

  if (isErrorSites || isErrorNestedFiltersValues) {
    return <ErrorMessage/>;
  }

  const allFilters = [
    {
      category: "site",
      values: sites.all,
      appliedFilter: site,
      setAppliedFilter: setSite,
      layout: SiteSelectionList,
    },
    {
      category: "product_family",
      values: nestedFiltersValues,
      appliedFilter: itemCodes,
      setAppliedFilter: setItemCodes,
      layout: NestedFilterList,
    },
  ];

  const handleClick = (category) => {
    const expandState = { ...expanded };
    expandState[category] = !expandState[category];
    setExpanded(expandState);
  };

  return (
    <FiltersList
      filters={allFilters}
      expanded={expanded}
      handleClick={handleClick}
    />
  );
};

export default MRPFilters;
