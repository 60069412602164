import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import { useOpenContractDrawer } from '../containers/Contract/hooks';

const ContractLink = ({ contractId }) => {
  const openContractDrawer = useOpenContractDrawer();
  return (
    <Link
      onClick={() => openContractDrawer(contractId)}
      style={{ cursor: 'pointer' }}
      underline="hover"
    >
      {contractId}
    </Link>
  );
};

ContractLink.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default ContractLink;
