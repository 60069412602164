import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import NestedFilterNode from './NestedFilterNode';
import { searchOnFilterValues } from './helpers';

const NestedFilterList = ({ filterValues, appliedFilter, setAppliedFilter }) => {
  const [searchQuery, setSearchQuery] = React.useState('');

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      className='filters-section'
      dense
    >
      <ListItem>
        <TextField
          className={"filter-search-box"}
          id={"filter-search-box"}
          type={"text"}
          size="small"
          placeholder={`Search...`}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
      </ListItem>
      {searchOnFilterValues(filterValues, searchQuery).map((node) => (
        <NestedFilterNode 
          key={node.name} 
          node={node} 
          appliedFilter={appliedFilter} 
          setAppliedFilter={setAppliedFilter} 
          searchQuery={searchQuery}
        />
      ))}
    </List>
  );
};

export default NestedFilterList;
