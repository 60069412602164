export const currencySorter = (a, b) => a.value - b.value;

export const currencyFormatter = (currency, minimumFractionDigits=2, maximumFractionDigits=2, notation="standard") => {
  if (currency === 'N/A') {
    currency = 'USD';
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
    notation: notation,
  });
};

