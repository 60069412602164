import { useBackendAPIGetRequest } from '../../connectors/apis';

export const useMRPItemCodeDemand = (itemCode, view, selectedTimeColumn, site, virtualContractsView) => {
  let path = `/mrp/contract_explore/${itemCode}?view=${view}&selected_time=${selectedTimeColumn}&virtual_contracts_view=${virtualContractsView}`;
  if (site){
    path += `&site=${encodeURIComponent(site)}`;
  }
  return useBackendAPIGetRequest({
    queryKey: ['mrp_contract_explore', itemCode, view, selectedTimeColumn, virtualContractsView],
    path: path,
    enabled: !!itemCode,
  });
};
