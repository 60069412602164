import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import ContractedDemandTable from '../../components/ContractedDemandTable';
import { useMRPItemCodeDemand } from './hooks';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import './styles.scss';

const MRPDemandDrillDown = ({ itemCode, currentColumn, currentColumnName, view, site, virtual_contracts_view }) => {
  const { data, isLoading, isError } = useMRPItemCodeDemand(itemCode, view, currentColumn, site, virtual_contracts_view);

  if (isLoading) {
    return <Loader/>;
  }

  if (isError) {
    return <ErrorMessage/>;
  }

  return (
    <Stack spacing={1} sx={{ height: '100%' }}>
      <Typography className="mrp-cell-drilldown-drawer-section-title">
        {`Contracted Demand For ${site}:`} <span>{`${itemCode} (${currentColumnName})`}</span>
      </Typography>
      <ContractedDemandTable data={data} firstDemandColumn={currentColumn} view={view} withDemandQuantity={true}/>
    </Stack>
  );
};

MRPDemandDrillDown.propTypes = {
  itemCode: PropTypes.string.isRequired,
  currentColumn: PropTypes.string.isRequired,
  currentColumnName: PropTypes.string.isRequired,
  view: PropTypes.oneOf(['week', 'month']),
  site: PropTypes.string,
};

MRPDemandDrillDown.defaultProps = {
  view: 'week',
};

export default MRPDemandDrillDown;
