import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ContractLink from '../ContractLink';
import { currencyFormatter } from "../../utils/currency";
import {
  GRID_COL_TYPE_CURRENCY,
  GRID_COL_TYPE_DATE,
  GRID_COL_TYPE_NUMBER,
  GRID_COL_TYPE_PERCENT,
} from "../../constants";
import Palette from '../../styling/palette.scss';

export const parseDetailValue = (value, columnType, isContract = false) => {
  if (isContract){
    return <ContractLink contractId={value}/>;
  }
  switch (columnType) {
    case GRID_COL_TYPE_CURRENCY:
      return value === 'N/A' ? value: currencyFormatter(value.split(' ')[1]).format(Number(value.split(' ')[0]));
    case GRID_COL_TYPE_PERCENT:
      if (value === 'N/A') {
        return value;
      }
      return typeof value === 'string' ? `${Number(value.replace('%', ''))}%` :`${value}%`;
    case GRID_COL_TYPE_NUMBER:
      return isNaN(value) ? value: parseFloat(value).toFixed(2);
    case GRID_COL_TYPE_DATE:
      return value.indexOf(',') > -1 ? value.split(',').map((v)=> new Date(v).toLocaleDateString('he-IL')).join(', '): value && new Date(value).toLocaleDateString('he-IL');
    default:
      return value;
  }
};

export const detailsPropTypeShape = PropTypes.arrayOf(PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]).isRequired,
})).isRequired;

const DetailsList = ({ details, itemsPerRow, labelGridColSpan, valueGridColSpan, ...props }) => (
  <Grid
    container
    spacing={1}
    style={{ fontSize: '14px', textAlign: 'left' }}
    justifyContent="baseline"
    columns={{ xs: 12, lg: 12 * itemsPerRow }}
    {...props}
  >
    {details.map((detail) => (
      <React.Fragment key={detail.label}>
        <Grid item xs={labelGridColSpan} >
          <Paper style={{ padding: '0.5rem', backgroundColor: 'inherit' }}>
            {`${detail.label}:`}
          </Paper>
        </Grid>
        <Grid item xs={valueGridColSpan}>
          <Paper
            style={{ 
              borderRadius: '0.9rem',
              padding: '0.5rem',
              backgroundColor: Palette.DarkThemeBackgroundSecondary,
              color: Palette.DarkThemeTextDisabled, 
            }}
          >
            {detail.value}
          </Paper>
        </Grid>
      </React.Fragment>
    ))}
  </Grid>
);

DetailsList.propTypes = {
  details: detailsPropTypeShape,
  itemsPerRow: PropTypes.number.isRequired,
  labelGridColSpan: PropTypes.number.isRequired,
  valueGridColSpan: PropTypes.number.isRequired,
};

DetailsList.defaultProps = {
  itemsPerRow: 1,
  labelGridColSpan: 4,
  valueGridColSpan: 8,
};

export default DetailsList;
