import shallow from 'zustand/shallow';
import useStore from './useStore';

export const useEditedDemandBySiteAndItemCode = (site, itemCode) => (
  useStore((state) => state.editedDemand[site] ? (state.editedDemand[site][itemCode] || {} ) : {})
);

export const useUpdateEditedDemand = (site, itemCode) => {
  const editedDemandUpdater = useStore((state) => state.editedDemandUpdater);
  return editedDemandUpdater(site, itemCode);
};

export const useSite = () => (
  useStore((state) => ({ site: state.site, setSite: state.setSite }), shallow)
);

export const useProductFamily = () => (
  useStore((state) => ({ productFamily: state.productFamily, setProductFamily: state.setProductFamily }), shallow)
);

export const useSelectedContract = () => (
  useStore((state) => ({ selectedContract: state.selectedContract, setSelectedContract: state.setSelectedContract }), shallow)
);

export const useProductFamilyAndItemCodes = () => (
  useStore((state) => ({ productFamily: state.productFamily, itemCodes: state.itemCodes, setProductFamilyAndItemCodes: state.setProductFamilyAndItemCodes }), shallow)
);

export const useItemCodes = () => (
  useStore((state) => ({ itemCodes: state.itemCodes, setItemCodes: state.setItemCodes }), shallow)
);

export const useServiceWorkerRegistration = () => (
  useStore((state) => ({ 
    serviceWorkerRegistration: state.serviceWorkerRegistration,
    setServiceWorkerRegistration: state.setServiceWorkerRegistration, 
  }), shallow)
);

export const useDrilldownItemCode = () => (
  useStore((state) => ({ drilldownItemCode: state.drilldownItemCode, setDrilldownItemCode: state.setDrilldownItemCode }), shallow)
);

export const useDrilldownCurrentColumn = () => (
  useStore((state) => ({ drilldownCurrentColumn: state.drilldownCurrentColumn, setDrilldownCurrentColumn: state.setDrilldownCurrentColumn }), shallow)
);

export const useDrilldownType = () => (
  useStore((state) => ({ drilldownType: state.drilldownType, setDrilldownType: state.setDrilldownType }), shallow)
);

export const useDrilldownCurrentColumnName = () => (
  useStore((state) => ({ drilldownCurrentColumnName: state.drilldownCurrentColumnName, setDrilldownCurrentColumnName: state.setDrilldownCurrentColumnName }), shallow)
);

export const useMRPView = () => (
  useStore((state) => ({ mrpView: state.mrpView, setMrpView: state.setMrpView }), shallow)
);

export const useMRPShowOnlyError = () => (
  useStore((state) => ({ mrpShowOnlyError: state.mrpShowOnlyError, setMrpShowOnlyError: state.setMrpShowOnlyError }))
);

export const useMRPTablesSort = () => (
  useStore((state) => ({ mrpTableSort: state.mrpTableSort, setMrpTableSort: state.setMrpTableSort }))
);

export const useVirtualContracts = () => (
  useStore((state) => ({ virtualContractsView: state.virtualContractsView, setVirtualContractsView: state.setVirtualContractsView }), shallow)
);
