import React from "react";
import PropTypes from "prop-types";
import {
  AreaChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from "recharts";
import { getWeekMonthForDisplay } from "../utils/dateTime";
import Palette from '../styling/palette.scss';

const MRPGraph = ({ data, yAxisLabel, view }) => {
  if (!data) {
    return <div/>;
  }
  let yAxisTopLimit = data.over_stock
    ? Math.max(data.over_stock, ...data.points.map((item) => item.value)) * 1.1
    : Math.max(...data.points.map((item) => item.value)) * 1.1; // makes sure the graph is not too high or too low

  yAxisTopLimit = yAxisTopLimit < 1 ? yAxisTopLimit : Math.ceil(yAxisTopLimit);

  const formatTick = (value) => {
    if (value >= 10) {
      return Number(5 * Math.round(value/5)).toLocaleString();
    }
    if (value < 1) {
      return Number(value.toFixed(2)).toLocaleString();
    }
    return Number(value.toFixed(0)).toLocaleString();
  };
  
  const startPoint = Math.min(0, ...data.points.map((item) => item.value));
  const tickInterval = (yAxisTopLimit - startPoint) / 4;
  const ticks = Array.from({ length: 5 }, (_, i) => startPoint + (i * tickInterval));

  if (data.safety_stock) {
    data.points = data.points.map((obj) => ({
      ...obj,
      belowSafety: obj.value <= data.safety_stock ? obj.value : undefined,
    }));
  }
  data.points = data.points.map((item) => ({ view: getWeekMonthForDisplay(view, item[view]), ...item }));
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const className = payload.length > 1 && payload[1].name === 'belowSafety' ? "red": "";
      const allValues = data.points.filter((item) => item.view === label).map((item) => item.value);
      const res = allValues.length > 1 ? Math.max(...allValues): allValues[0];
      return (
        <div>
          <p>{`${label}: `}<span className={className}>{`${res.toLocaleString()}`}</span></p>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="100%" className="mrp-graph">
      <AreaChart
        width={400}
        height={300}
        data={data.points}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#08E0EA" stopOpacity={0.8}/>
            <stop offset="86.46%" stopColor="rgba(103, 139, 253, 0)" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis
          dataKey={'view'}
          allowDuplicatedCategory={false}
          tick={{ fill: Palette.DarkThemeText, fontSize: '12px' }}
        />
        <YAxis
          domain={[0, yAxisTopLimit]}
          type="number"
          tick={{ fill: Palette.DarkThemeText, fontSize: '12px' }}
          width={100}
          padding={{ top: 30 }}
          tickFormatter={formatTick}
          interval={0}
          ticks={[0, ...ticks]}
        >
          <Label
            value={yAxisLabel || ''}
            fill="white"
            position="insideTop"
            offset={-1}
            fontSize={ '13px' }
          />
        </YAxis>
        <ReferenceLine y={0} stroke="white" strokeWidth={2}/>
        <Tooltip content={<CustomTooltip />}/>
        <Area
          type="monotone"
          fillOpacity={1}
          fill="url(#colorUv)"
          dataKey="value"
          stroke="#08E0EA"
        />
        <Line
          type="linear"
          dataKey="value"
          stroke={Palette.DarkThemePrimary}
          dot={false}
          activeDot={{ stroke: Palette.DarkThemePrimary }}
          strokeWidth={2}
        />
        {data.safety_stock && (
          <>
            <ReferenceLine
              y={data.safety_stock}
              stroke="red"
              strokeDasharray="3 3"
              strokeWidth={2}
            >
              <Label
                fill="white"
                value="Safety Stock"
                position="insideBottom"
                fontSize="14px"
              />
            </ReferenceLine>
            <Area
              type="monotone"
              fillOpacity={0.5}
              fill="red"
              dataKey="belowSafety"
              stroke="red"
            />
          </>
        )}
        {data.over_stock && (
          <ReferenceLine
            y={data.over_stock}
            stroke="green"
            strokeDasharray="3 3"
            strokeWidth={2}
          >
            <Label
              fill="white"
              value="Over Stock"
              position="insideBottom"
              fontSize="14px"
            />
          </ReferenceLine>
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

MRPGraph.propTypes = {
  data: PropTypes.object.isRequired,
  yAxisLabel: PropTypes.string,
  view: PropTypes.oneOf(['week', 'month']),
};

MRPGraph.defaultProps = {
  view: 'week',
};

export default MRPGraph;
