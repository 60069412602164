import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import ScopeDataGrid from "../../components/widgetLib/ScopeDataGrid";
import { useContractDetailedFulfillmentForTime } from './hooks';
import { numberColumn, renderCellWithTooltip } from "../../utils/gridColumns";
import { getWeekMonthForDisplay } from "../../utils/dateTime";

const ContractDetailedFulfillment = ({ contractId, view, selectedTime }) => {
  const { data, isLoading, isError } = useContractDetailedFulfillmentForTime(contractId, selectedTime, view);

  if (isLoading) {
    return <Loader/>;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  const columns = [
    {
      field: 'batch_number',
      headerName: 'Batch',
      flex: 1,
      renderCell: renderCellWithTooltip,
      minWidth: 90,
    },
    {
      field: 'ItemCode',
      headerName: 'Item Code',
      renderCell: renderCellWithTooltip,
      flex: 1.2,
      minWidth: 150,
    },
    {
      field: 'brix_range',
      headerName: 'Brix Range',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'ac_range',
      headerName: 'Acidity Range',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'ratio_range',
      headerName: 'Ratio Range',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'used_quantity',
      headerName: 'Used Quantity',
      flex: 1,
      minWidth: 90,
      ...numberColumn(),
    },
  ];  
  return (
    <>
      <Typography className={"contract-section-title"}>
            Detailed Fulfillment for Contract {contractId}: {getWeekMonthForDisplay(view, selectedTime)}
      </Typography>
      <ScopeDataGrid
        columns={columns}
        rows={data}
        isCellEditable={() => false}
        getRowId={(row) => `${row.batch_number}_${row.ItemCode}_${row.used_quantity}`}
        style={{
          width: '100%',
        }}
        isAutoHeight={true}
      />
    </>
  );  
};

ContractDetailedFulfillment.defaultProps = {
  view: 'week',
};

ContractDetailedFulfillment.propTypes = {
  contractId: PropTypes.string.isRequired,
  view: PropTypes.oneOf(['week', 'month']),
  selectedTime: PropTypes.string.isRequired,
};

export default ContractDetailedFulfillment;
