import React from "react";
import KPIWithGraph from "../../../components/widgetLib/KpiWithGraph";
import { useTurnover } from "../hooks";
import Loader from "../../../components/Loader";
import ErrorMessage from "../../../components/ErrorMessage";
import { kpiColor } from "./constants";
import LineGraph from "../../../components/widgetLib/LineGraph";
import PropTypes from "prop-types";

const TurnOver = ({ filters, elementHeight }) => {
  const { data, isLoading, isError } = useTurnover(filters);

  if (isLoading) {
    return <Loader/>;
  }
  if (isError) {
    return <ErrorMessage />;
  }
  const color = kpiColor['expected_overstock'][data.kpiDirection];
  const graphHeight = elementHeight > 300 ? elementHeight/1.5 : elementHeight/2;
  return (
    <KPIWithGraph
      title={'Turnover'}
      value={data.value}
      isCurrency={false}
      unit={'weeks'}
      className={'dashboard-kpi'}
      kpiPercent={data.kpiPercent.replace('-', '').replace('+', '')}
      color={color}
      kpiDirection={data.kpiDirection}
      content={<LineGraph data={data.graphData} elementHeight={graphHeight}/>}
    />
  );
};

TurnOver.propTypes = {
  filters: PropTypes.object.isRequired,
  elementHeight: PropTypes.number,
};

export default TurnOver;