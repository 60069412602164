import React from "react";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import MRPTable from '../../components/MRPTable';
import DetailsList from '../../components/widgetLib/DetailsList';

const MRPTableLayout = ({ data, itemCode, onItemCodeClick, dataColumns, view, virtualContractsView }) => {
  let itemCodeDetails = [
    {
      label: 'Product Family',
      value: data.rows[0].product_family,
    },
    {
      label: 'Site',
      value: data.rows[0].site,
    },
  ];

  const itemCodeParts = [...data.rows[0].item_code.split("_")];
  if (itemCode.includes("ratio")) {
    itemCodeDetails = [
      {
        label: "Item Code",
        value: itemCodeParts[0],
      },
      {
        label: "Ratio",
        value: itemCodeParts[2],
      },
      ...itemCodeDetails,
    ];
  } else {
    itemCodeDetails = [
      {
        label: "Item Code",
        value: data.rows[0].item_code,
      },
      ...itemCodeDetails,
    ];
  }

  return (
    <React.Fragment>
      <div className="spec-title">
        <div
          className="spec-item-code"
          onClick={onItemCodeClick}
        >
          {itemCode}
        </div>
      </div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <DetailsList details={itemCodeDetails} spacing={1.1} />
        </Grid>
        <Grid item xs={9}>
          <MRPTable
            data={data}
            dataColumns={dataColumns}
            view={view}
            virtualContractsView={virtualContractsView}
            itemCode={itemCode}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

MRPTableLayout.propTypes = {
  data: PropTypes.object.isRequired,
  itemCode: PropTypes.string.isRequired,
  dataColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  view: PropTypes.oneOf(['week', 'month']),
};

MRPTableLayout.defaultProps = {
  view: 'week',
};

export default MRPTableLayout;
