import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Paper from "@mui/material/Paper";
import InsightTable from './InsightTable';
import { useInsights } from './hooks';
import Loader from '../../components/Loader';
import ErrorMessage from "../../components/ErrorMessage";
import ScopeDrawer from '../../components/widgetLib/ScopeDrawer';
import InsightDrillDown from './InsightDrillDown';
import { InsightsContractProblemIcon } from "../ScopeIcons";
import { useSendAnalytics } from '../../analytics/analytics';
import eventTypes from '../../analytics/eventTypes';
import { APP_WINDOW_HEIGHT } from "../../constants";
import './styles.scss';


const InsightsPage = () => {
  const sendAnalyitcs = useSendAnalytics();
  const { data, isLoading, isError } = useInsights();
  const [selectedInsightType, setSelectedInsightType] = useState({});
  const [selectedDrillDownInsight, setSelectedDrillDownInsight] = useState(null);

  if (isLoading) {
    return <Loader/>;
  }

  if (isError) {
    return <ErrorMessage/>;
  }

  const onListItemClick = (insightType) => {
    sendAnalyitcs(eventTypes.insights.insightTypeClicked, { insight_type: insightType.type });
    setSelectedInsightType(insightType);
  };

  return (
    <Grid container item columnSpacing={3} sx={{ height: `${APP_WINDOW_HEIGHT}vh`, padding: '0.5rem' }}>
      <Grid item xs={12} sx={{ textAlign: 'left', padding: '0 0 10px 0', margin: 0 }}>
        <Typography sx={{ fontWeight: 600, fontSize: '28px' }}>
                    Scope Insights
        </Typography>
      </Grid>
      <Grid
        item
        xs={5}
        xl={3}
        sx={{ height: '94%' }}
      >
        <Paper
          sx={{
            backgroundColor: 'background.secondary',
            padding: "0.5rem",
            borderRadius: '0.9rem',
            overflowY: 'auto',
            height: '100%',
          }}>
          <List disablePadding>
            {data.map((insightType) => (
              <ListItem
                key={`insight-li-${insightType.id}`}
                sx={{
                  minHeight: '4rem',
                  backgroundColor: 'background.default',
                  margin: '0.5rem 0',
                  borderRadius: '0.5rem',
                }}
              >
                <ListItemIcon>
                  {insightType.icon || <InsightsContractProblemIcon/>}
                </ListItemIcon>
                <ListItemButton
                  onClick={() => onListItemClick(insightType)}
                  selected={selectedInsightType.type === insightType.type}
                  sx={{
                    borderRadius: '0.9rem',
                    "&.Mui-selected": { background: 'linear-gradient(90deg, rgba(8, 224, 234, 0.99) 1.24%, rgba(103, 139, 253, 0) 100%)' },
                  }}
                >
                  <ListItemText
                    sx={{ ".insights-item-text": { color: insightType.color } }}
                    primary={<span>{insightType.amount_of_insights} <span className={'insights-item-text'}>{insightType.title}</span></span>}
                  />
                </ListItemButton>
         
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
      <Grid
        item
        container
        xs={7}
        xl={9}
        sx={{
          height: 'calc(100% - 6rem)',
          overflow: 'auto',
        }}
      >
        {!!Object.keys(selectedInsightType).length && (
          <Grid item container justifyContent="center" alignItems="flex-start">
            <InsightTable
              insightType={selectedInsightType}
              openDrillDownDrawer={setSelectedDrillDownInsight}
            />
          </Grid>
        )}
        {!!Object.keys(selectedInsightType).length && selectedDrillDownInsight && (
          <ScopeDrawer
            open
            onClose={() => setSelectedDrillDownInsight(null)}
            className="insights-drill-down-drawer"
            content={
              <InsightDrillDown
                insightType={selectedInsightType}
                insight={selectedDrillDownInsight}
              />
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default InsightsPage;
