import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const SettingsSideMenuItem = ({ menuItem, disabled }) => (
  <ListItem className="settings-side-menu-item">
    <ListItemButton
      className="settings-side-menu-item-button"
      component={NavLink}
      to={menuItem.url}
      sx={{ "&.active": { background: 'linear-gradient(90deg, rgba(8, 224, 234, 0.99) 1.24%, rgba(103, 139, 253, 0) 100%)' } }} 
      disabled={disabled}
    >
      <ListItemIcon>{menuItem.icon}</ListItemIcon>
      <ListItemText primary={menuItem.title} />
    </ListItemButton>
  </ListItem>
);

SettingsSideMenuItem.propTypes = {
  menuItem: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.object,
  }).isRequired,
};

export default SettingsSideMenuItem;
