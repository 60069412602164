import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

const ColorLegend = ({ legend, direction }) => (
  <Stack
    direction={direction} spacing={2}
    alignItems="center"
    justifyContent="flex-start"
  >
    {legend.map((legendItem) => (
      <Stack 
        key={legendItem.color + legendItem.label}
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
      >
        <div 
          style={{ 
            backgroundColor: legendItem.color, 
            height: '1rem',
            width: '1rem',
            borderRadius: '0.1rem',
          }}
        />
        <span>
          {legendItem.label}
        </span>
      </Stack>
    ))}
  </Stack>
);

ColorLegend.propTypes = {
  legend: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  direction: PropTypes.oneOf(['row', 'column']),
};

ColorLegend.defaultProps = {
  direction: 'row',
};

export default ColorLegend;
