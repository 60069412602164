import React from 'react';
import PropTypes from 'prop-types';
import FilterCheckboxList from '../../components/filterCheckboxList';
import ErrorMessage from "../../components/ErrorMessage";
import Loader from '../../components/Loader';
import FiltersList from '../../components/FiltersList';
import { useDashboardFilterValues } from './hooks';

const DashboardFilters = ({ selectedSites, setSelectedSites }) => {
  const [expanded, setExpanded] = React.useState({ "site": true });

  const { data: sites, isLoading: isLoadingSites, isError: isSitesError } = useDashboardFilterValues('site');

  if (isLoadingSites) {
    return <Loader/>;
  }

  if (isSitesError) {
    return <ErrorMessage/>;
  }

  const handleClick = (category) => {
    const expandState = { ...expanded };
    expandState[category] = !expandState[category];
    setExpanded(expandState);
  };

  const allFilters = [
    {
      category: "site",
      values: sites,
      appliedFilter: selectedSites,
      setAppliedFilter: setSelectedSites,
      layout: FilterCheckboxList,
    },
  ];
  
  return (
    <FiltersList
      filters={allFilters}
      expanded={expanded}
      handleClick={handleClick}
    />
  );
};

DashboardFilters.propTypes = {
  selectedSites: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedSites: PropTypes.func.isRequired,
};

export default DashboardFilters;
