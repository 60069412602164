import React from "react";
import KPIWithGraph from "../../../components/widgetLib/KpiWithGraph";
import { useNonMovingInventory } from "../hooks";
import Loader from "../../../components/Loader";
import ErrorMessage from "../../../components/ErrorMessage";
import { kpiColor } from "./constants";
import BarGraph from "../../../components/widgetLib/BaGraph";
import PropTypes from "prop-types";

const NonMovingInventory = ({ filters, elementHeight }) => {
  const { data, isLoading, isError } = useNonMovingInventory(filters);

  if (isLoading) {
    return <Loader/>;
  }
  if (isError) {
    return <ErrorMessage />;
  }

  const color = kpiColor['non_moving_inventory'][data.kpiDirection];
  const graphHeight = elementHeight > 300 ? elementHeight/1.5 : elementHeight/2;
  return (
    <KPIWithGraph
      title={'Non Moving Inventory'}
      value={data.value}
      isCurrency={false}
      unit={'mt'}
      className={'dashboard-kpi'}
      color={color}
      kpiPercent={data.kpiPercent.replace('-', '').replace('+', '')}
      kpiDirection={data.kpiDirection}
      content={<BarGraph data={data.graphData} elementHeight={graphHeight} />}
    />
  );
};

NonMovingInventory.propTypes = {
  filters: PropTypes.object.isRequired,
  elementHeight: PropTypes.number,
};

export default NonMovingInventory;