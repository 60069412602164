import React from "react";
import PropTypes from "prop-types";

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Palette from "../../styling/palette.scss";
import _ from "lodash";

const BarGraph = ({ data, elementHeight }) => {
  if (!data){
    return <div/>;
  }

  return (
    <ResponsiveContainer width="100%" height={elementHeight}>
      <BarChart
        width={500}
        height={elementHeight}
        data={data}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.2} vertical={false} />
        <XAxis dataKey="month" tick={{ fill: Palette.DarkThemeTextDisabled, fontSize: '0.8rem' }} angle={-45}/>
        <YAxis tick={{ fill: Palette.DarkThemeTextDisabled, fontSize: '0.8rem' }} domain={['auto', 'auto']}/>
        <Tooltip
          contentStyle={{ background: Palette.DarkThemeBackgroundPaper, borderRadius: '2rem', fontSize: '0.8rem' }}
          wrapperStyle={{ borderRadius: '2rem' }}
          cursor={{ fill: 'transparent' }}
          labelFormatter={(value) => _.startCase(value)}
          formatter={(value, name, props) => [value, _.startCase(props.payload.variable)]}
        />
        <Bar dataKey="value" fill={Palette.DarkThemeColorCodePurple} radius={[4, 4, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

BarGraph.propTypes = {
  data: PropTypes.array.isRequired,
  elementHeight: PropTypes.number,
};

export default BarGraph;
