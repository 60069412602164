import React from "react";
import PropTypes from "prop-types";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useViewport } from "../../utils/useViewport";
import { APP_WINDOW_HEIGHT } from "../../constants";
import { useSendAnalytics } from '../../analytics/analytics';
import eventTypes from '../../analytics/eventTypes';


const ScopeDataGrid = ({
  rows,
  columns,
  processRowUpdate,
  handleProcessRowUpdateError,
  getRowId,
  style,
  exportFileName,
  dataGridTitle,
  shouldHideFooter,
  ...props
}) => {

  const sendAnalytics = useSendAnalytics();

  const getRowSpacing = React.useCallback((params) => ({
    top: 5,
    bottom: 5,
  }), []);

  const { height: windowHeight } = useViewport();
  /*
      54 => avg. height of row in Mui DataGrid
      118 => height of application header + page title
      52 => height of footer
      56 => height of header row
      35 => height of toolbar (export)
     */
  let toReduce = 56 + 118;
  toReduce += !props.shouldHideFooter ? 52 : 0;
  toReduce += props.exportFileName || (props.components && props.components.Toolbar) ? 52 : 0;
  const heightForComparison = props.elementHeight ? props.elementHeight : (windowHeight * (APP_WINDOW_HEIGHT / 100));
  const isAutoHeight = props.isAutoHeight || rows.length === 1 || rows.length * 54 <(heightForComparison - toReduce);

  const getRowsToExportWithAnalytics = (params) => {
    sendAnalytics(eventTypes.global.tableExportedToCSV, { file_name: exportFileName });
    return params.apiRef.current.getSortedRowIds();
  };

  const CustomToolbar = () => (
    <GridToolbarContainer sx={{ justifyContent: 'end' }}>
      {dataGridTitle &&
                <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography variant="h6">{dataGridTitle}</Typography>
                </Box>
      }
      <GridToolbarExport
        csvOptions={{
          fileName: exportFileName,
          getRowsToExport: getRowsToExportWithAnalytics,
          utf8WithBom: true,
        }}
        sx={{ backgroundColor: 'background.secondary' }}
      />
    </GridToolbarContainer>
  );

  return (
    <DataGrid
      experimentalFeatures={{ newEditingApi: true }}
      sx={{
        '& .MuiDataGrid-cell--editable': {
          cursor: 'pointer',
        },
      }}
      getRowSpacing={getRowSpacing}
      style={style}
      columns={columns}
      getRowHeight={() => 'auto'}
      rows={rows}
      autoHeight={isAutoHeight}
      hideFooter={shouldHideFooter}
      getRowId={getRowId}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      components={exportFileName ? { Toolbar: CustomToolbar } : {}}
      {...props}
    />
  );
};

ScopeDataGrid.defaultProps = {
  shouldHideFooter: true,
};

ScopeDataGrid.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  processRowUpdate: PropTypes.func,
  handleProcessRowUpdateError: PropTypes.func,
  getRowId: PropTypes.func,
  style: PropTypes.object,
  exportFileName: PropTypes.string,
  shouldHideFooter: PropTypes.bool,
};

export default ScopeDataGrid;
