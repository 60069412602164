import { useBackendAPIGetRequest } from '../../connectors/apis';

export const useInsights = () => useBackendAPIGetRequest({
  queryKey: ['insights'],
  path: '/insights',
});

export const useInsightsByType = (insightType) => useBackendAPIGetRequest({
  queryKey: ['insightsByType', insightType.type],
  path: `/insights/${insightType.type}?insight_source=${insightType.source}`,
});

export const useProductionRecommendationDrillDown = (workOrderNumber) => useBackendAPIGetRequest({
  queryKey: ['productionRecommendationDrillDown', workOrderNumber],
  path: `/insights/production_recommendation/${workOrderNumber}`,
});

export const useMissingSpecsDrillDown = (insightId) => useBackendAPIGetRequest({
  queryKey: ['missingSpecsDrillDown', insightId],
  path: `/insights/missing_spec_drilldown/${insightId}`,
});
