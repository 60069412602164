import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSendAnalytics } from '../../analytics/analytics';

export const hasChildren = (node) => !!node.children;

export const getLeaves = (node) => (
  _.flatMap(node.children, (child) => (hasChildren(child) ? getLeaves(child) : child.name))
);

const getAllChildren = (node, allChildren = []) => {
  if (!node) {
    return allChildren;
  }
  allChildren.push(node.name); 
  if (node.children) {
    node.children.forEach((child) => {getAllChildren(child, allChildren);});
  }
  return allChildren;
};

export const nodeHasChildrenWithQuery = (node, searchQuery) => {
  const allChildren = getAllChildren(node);
  return allChildren.some((child) => child && child.toLowerCase().includes(searchQuery.toLowerCase()));
};

export const searchOnFilterValues = (nodeChildren, searchQuery) => {
  const filtered = nodeChildren.filter((child) => (
    nodeHasChildrenWithQuery(child, searchQuery)
  ));
  return filtered;
};

const findHighestSelectedNodes = (tree, leafList) => {
  const results = []; 
  // Define a recursive function to traverse the tree
  const traverse = (node) => {
    if (!node) {
      return false;
    } 
    // Check if all child nodes are in the list of leaves
    const allLeaves = getLeaves(node);
    const leavesInList = allLeaves.filter((leaf) => leafList.includes(leaf));
        
    if (allLeaves.length === leavesInList.length && node.children) { // add to list
      results.push({ ...node, leaves: allLeaves });
    }
    else if (!node.children && leafList.includes(node.name)){ // if we got to a leaf node and it's in the list
      results.push({ ...node, leaves: [node.name] });
    }
    else if (leavesInList.length > 0){ // go done level deeper
      node.children.forEach((child) => traverse(child));
    }
    return false;
  };
    // Start the search from the root node
  traverse(tree);
  
  return results;
};

export const useNestedFilterChips = ({ useFilterValues, selectedFilters, setSelectedFilters, analyticsEventType }) => {
  const { data: filterValues } = useFilterValues();
  const sendAnalytics = useSendAnalytics();
  if (filterValues.length){
    const nodes = findHighestSelectedNodes({ name: 'root', children: filterValues }, selectedFilters);
    return nodes.map((node) => ({
      label: node.type,
      value: node.name,
      onDelete: () => {
        sendAnalytics(analyticsEventType, { 'type': node.type, 'item': node.name });
        setSelectedFilters(selectedFilters.filter((item) => !node.leaves.includes(item)));
      },
    }));
  }
  return [];
};

const nodeShape = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
nodeShape.children = PropTypes.arrayOf(PropTypes.shape(nodeShape));

export const nodePropTypeshape = PropTypes.shape(nodeShape);
