import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useIncomingInfo } from './hooks';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import ScopeDataGrid from '../../components/widgetLib/ScopeDataGrid';
import {
  dateColumn,
  numberColumn,
  renderCellWithTooltip,
} from "../../utils/gridColumns";

const IncomingDetails = ({ itemCode, view, currentColumn, currentColumnName, site }) => {
  const { data, isLoading, isError } = useIncomingInfo(itemCode, site, view, currentColumn);

  if (isLoading) {
    return <Loader />;
  }
    
  if (isError) {
    return (
      <ErrorMessage />
    );
  }

  const columns = [
    {
      field: 'document',
      headerName: 'Document Type',
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'DocNum',
      headerName: 'Doc Number',
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'supplier',
      headerName: 'Supplier',
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'ETA',
      flex: 1,
      ...dateColumn(),
    },
    {
      field: 'bill_of_landing',
      headerName: 'Bill of Landing',
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'Quantity',
      flex: 1,
      ...numberColumn(),
    },
  ];

  const totalRow = {
    document: 'Total Quantity',
    Quantity: data.map((item) => item['Quantity']).reduce((a, b) => a + b, 0).toFixed(2),
  };
  
  return (
    <React.Fragment>
      <Typography className="mrp-cell-drilldown-drawer-section-title">
        {'Incoming Information For:'} <span>{`${itemCode} (${currentColumnName})`}</span>
      </Typography>
      <ScopeDataGrid
        rows={data.length > 1 ? [...data, totalRow] :data}
        columns={columns}
        width="250px"
        getRowId={(row) => `${row.document}_${row.DocNum}`}
        getRowClassName={(params) => params.row.document === 'Total Quantity' ? 'incoming-table-total-row' : ''}
      />
    </React.Fragment>
  );
};

IncomingDetails.propTypes = {
  itemCode: PropTypes.string.isRequired,
  site: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  currentColumn: PropTypes.string.isRequired,
  currentColumnName: PropTypes.string.isRequired,
};

export default IncomingDetails;
