import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const FiltersList = ({ filters, expanded, handleClick }) => (
  <Stack className="filter-container">
    <Card variant="outlined" sx={{ width: 'inherit', borderRadius: '0.9rem' }}>
      <List
        component={Stack}
        sx={{ width: 'inherit', overflowY: 'auto', height: 'auto' }}
        spacing={0}
        disablePadding={true}
      >
        {filters.map((listItem, index) => (
          <React.Fragment key={`filters-fragment-${index}`}>
            <ListItemButton onClick={() => handleClick(listItem.category)}>
              {expanded[listItem.category] === true ? <RemoveIcon/> : <AddIcon/>}
              <ListItemText primary={_.startCase(listItem.category)}/>
            </ListItemButton>
              
            <Collapse in={expanded[listItem.category]} timeout="auto" unmountOnExit>
              <listItem.layout
                filterValues={listItem.values}
                appliedFilter={listItem.appliedFilter}
                setAppliedFilter={listItem.setAppliedFilter}
                category={listItem.category}
              />
            </Collapse>
          </React.Fragment>           
        ))}
      </List>
    </Card>
  </Stack>
);

FiltersList.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  expanded: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default FiltersList;
