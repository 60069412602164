import React from 'react';
import PropTypes from 'prop-types';
import ScopeToggle from "./ScopeToggle";

const WeekMonthToggle = ({ view, handleToggleView }) => (
  <ScopeToggle 
    handleToggle={handleToggleView} 
    selectedValue={view}
    toggleValues={[{ key: 'week', value: 'Week' }, { key: 'month', value: 'Month' }]}
  />
);

WeekMonthToggle.propTypes = {
  view: PropTypes.oneOf(['week', 'month']).isRequired,
  handleToggleView: PropTypes.func.isRequired,
};

export default WeekMonthToggle;
