import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import ContractedDemandTable from '../../components/ContractedDemandTable';
import { useContractFulfillment } from './hooks';

const ContractFulfillment = ({ contractId }) => {
  const { data, isLoading, isError } = useContractFulfillment(contractId);

  if (isLoading) {
    return <Loader/>;
  }

  if (isError) {
    return <ErrorMessage />;
  }
  return (
    <ContractedDemandTable
      data={data}
      view="month"
      withMetadata={false}
    />
  );
};

ContractFulfillment.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default ContractFulfillment;
