import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import ScopeDataGrid from "./widgetLib/ScopeDataGrid";
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import {
  MRP_TABLE_ROWS,
  MRP_ROW_TYPE_DEMAND,
  MRP_ROW_TYPE_INCOMING,
  MRP_ROW_TYPE_ON_HAND,
  MRP_ROW_TYPE_STOCK_COVER,
  mrpTableText,
} from "../constants";
import { getWeekMonthForDisplay } from "../utils/dateTime";
import { numberColumn } from "../utils/gridColumns";
import eventTypes from "../analytics/eventTypes";
import { useSendAnalytics } from "../analytics/analytics";
import {
  useDrilldownCurrentColumn,
  useDrilldownCurrentColumnName,
  useDrilldownItemCode,
  useDrilldownType,
  useMRPView,
} from "../state/hooks";
import "./styles.scss";

const MRPTable = ({ data, dataColumns, view, virtual_contracts_view, itemCode, ...props }) => {
  const sendAnalytics = useSendAnalytics();
  const { setDrilldownItemCode } = useDrilldownItemCode();
  const { setDrilldownCurrentColumn } = useDrilldownCurrentColumn();
  const { setDrilldownType } = useDrilldownType();
  const { setDrilldownCurrentColumnName } = useDrilldownCurrentColumnName();
  const { setMrpView } = useMRPView();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState(null);
  const [modalText, setModalText] = React.useState(null);

  const handleCellClick = (params) => {
    if (params.field === 'variable') {
      return;
    }
    setMrpView(view);
    if (params.id === MRP_ROW_TYPE_DEMAND && !isNaN(params.value)) {
      sendAnalytics(eventTypes.mrp.mrpDemandCellClicked, {
        item_code: itemCode,
        view,
        column: Number(params.field),
      });
      setDrilldownItemCode(itemCode);
      setDrilldownCurrentColumnName(params.colDef.headerName);
      setDrilldownCurrentColumn(params.field);
      setDrilldownType('demand');
    }
    if (
      params.id === MRP_ROW_TYPE_INCOMING &&
        data.rows.filter((r) => r.variable === 'incoming_source')[0][params.field] !== ''
    ){
      sendAnalytics(eventTypes.mrp.mrpIncomingCellClicked, {
        item_code: itemCode,
        view,
        column: Number(params.field),
      });
      setDrilldownItemCode(itemCode);
      setDrilldownCurrentColumnName(params.colDef.headerName);
      setDrilldownCurrentColumn(params.field);
      setDrilldownType('incoming');
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalTitle(null);
    setModalText(null);
  };

  const handleModalClick = (value) => {
    setModalTitle(_.startCase(value));
    if (value in mrpTableText) {
      setModalText(mrpTableText[value].modalText);
    }
    setIsModalOpen(true);
  };

  const getIncomingCellClassName = (incomingSource) => {
    switch (incomingSource) {
      case 'po':
        return 'mrp-table-cell-incoming-po';
      case 'suggestion':
        return 'mrp-table-cell-incoming-suggestion';
      case 'general_production':
        return 'mrp-table-cell-incoming-general-production';
      case 'po_and_general_production':
        return 'mrp-table-cell-incoming-po-general-production';
      case 'po_and_suggestion':
        return 'mrp-table-cell-incoming-po-suggestion';
      case 'suggestion_and_general_production':
        return 'mrp-table-cell-incoming-suggestion-general-production';
      case 'po_general_production_and_suggestion':
        return 'mrp-table-cell-incoming-po-general-production-suggestion';
      default:
        return '';
    }
  };

  const getStockCellClassName = (stockCoverValue, safetyStock, overStock) => {
    if (stockCoverValue > overStock) {
      return 'mrp-table-cell-over-stock';
    }
    if (stockCoverValue < safetyStock) {
      return 'mrp-table-cell-under-stock';
    }
    return '';
  };

  const getCellClassName = (params) => {
    if (params.id === MRP_ROW_TYPE_INCOMING) {
      const incomingSource = data.rows.filter((row) => row.variable === 'incoming_source')[0][params.field];
      return getIncomingCellClassName(incomingSource);
    }
    if ([MRP_ROW_TYPE_ON_HAND, MRP_ROW_TYPE_STOCK_COVER].includes(params.id)) {
      const stockCoverValue = stockCoverRow[params.field];
      return getStockCellClassName(stockCoverValue, data.safety_stock, data.over_stock);
    }
    return (params.id === MRP_ROW_TYPE_DEMAND && params.field !== 'variable' && !isNaN(params.value))
      ? "mrp-table-clickable-value"
      : "mrp-table-value";
  };

  const columns = [
    {
      field: "variable",
      headerName: "variable",
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
          <Tooltip title={mrpTableText[params.value].tooltip}>
            <IconButton
              edge={"start"} size={"small"} onClick={(e) => {
                e.preventDefault();
                handleModalClick(params.value);
              }}><HelpOutlineRoundedIcon fontSize={"small"}/></IconButton>
          </Tooltip>
          <span>{_.startCase(params.value)}</span>
        </div>
      ),
      sortable: false,
      minWidth: 130,
      cellClassName: getCellClassName,
    },
    {
      field: "today",
      headerName: "Today",
      renderCell: (params) => params.id === MRP_ROW_TYPE_ON_HAND ? data.today : '',
      sortable: false,
      flex: 0.8,
      minWidth: 80,
      ...numberColumn(),
    },
    ...dataColumns.map((column) => ({
      field: column.toString(),
      headerName: getWeekMonthForDisplay(view, column),
      sortable: false,
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <Tooltip title={params.value && !isNaN(params.value) ? params.value : "—"}>
          <span>{params.value && !isNaN(params.value) ? params.value : "—"}</span>
        </Tooltip>),
      valueFormatter: (params) => params.value && !isNaN(params.value) ? params.value : "—",
      cellClassName: getCellClassName,
      minWidth: 80,
      ...numberColumn(),
    })),
  ];
  const rows = data.rows.filter((row) => MRP_TABLE_ROWS.includes(row.variable));
  const stockCoverRow = rows.filter((row) => row.variable === MRP_ROW_TYPE_STOCK_COVER)[0];

  const ordering = {};
  const sortOrder = ['incoming', 'demand', 'on_hand', 'stock_cover'];
  for (let i = 0; i < sortOrder.length; i++)
    ordering[sortOrder[i]] = i;

  rows.sort(function (a, b) {
    return (ordering[a.variable] - ordering[b.variable]);
  });

  return (
    <React.Fragment>
      <ScopeDataGrid
        rows={rows}
        columns={columns}
        className="mrp-table"
        isCellEditable={() => false}
        disableColumnSelector={true}
        disableColumnMenu={true}
        disableColumnFilter={true}
        disableColumnSort={true}
        getRowId={(row) => row.variable}
        style={{ width: '100%' }}
        isAutoHeight={true}
        onCellClick={handleCellClick}
        {...props}
      />
      {!!isModalOpen && <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={'mrp-modal'}
      >
        <Box sx={{ p: 4 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalTitle}
          </Typography>
          <Typography id="modal-modal-description"  sx={{ mt: 2 }} component="div">
            {modalText}
          </Typography>
        </Box>
      </Modal>}
    </React.Fragment>
  );
};

MRPTable.propTypes = {
  data: PropTypes.object.isRequired,
  dataColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  view: PropTypes.oneOf(['week', 'month']),
  virtual_contracts_view: PropTypes.oneOf(['include', 'exclude']),
  itemCode: PropTypes.string.isRequired,
};

MRPTable.defaultProps = {
  view: 'week',
  virtual_contracts_view: 'include',
};

export default MRPTable;
