export const convertObjectToURLString = (obj, stringify = true) => {
  if (!Object.keys(obj).length) {
    return '';
  }
  return Object.keys(obj)
    .filter((key) => !!obj[key])
    .map((key) => (
      obj[key]
        ? `${key}=${encodeURIComponent(stringify ? JSON.stringify(obj[key]) : obj[key])}`
        : ''
    ))
    .join('&');
};

export const addQueryParamsToPath = (path, activeFilters, params) => {
  const filtersAsString = convertObjectToURLString(activeFilters);
  let pathWithFilters = `${path}?${filtersAsString}`;
  // the other query params values should be converted to &param=value instead of &param="value"
  const queryParamsString = convertObjectToURLString(params, false);
  if (queryParamsString) {
    pathWithFilters += `&${queryParamsString}`;
  }
  return pathWithFilters;
};

export const addFiltersToPath = (originalPath, site, productFamilies, itemCodes) => {
  let path = `${originalPath}?${convertObjectToURLString({ site }, false)}`;
  if (productFamilies.length){
    path += `&${convertObjectToURLString({ product_family: productFamilies })}`;
  }
  if (itemCodes.length){
    path += `&${convertObjectToURLString({ item_codes: itemCodes })}`;
  }
  return path;
};
