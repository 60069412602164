import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { useMissingSpecsDrillDown } from '../hooks';
import Loader from '../../../components/Loader';
import ErrorMessage from '../../../components/ErrorMessage';
import ContractedDemandTable from '../../../components/ContractedDemandTable';
import DrillDownHeader from './DrillDownHeader';
import ScopeDataGrid from '../../../components/widgetLib/ScopeDataGrid';
import DetailsList, { detailsPropTypeShape } from '../../../components/widgetLib/DetailsList';
import {
  numberColumn,
  renderCellWithTooltip,
} from "../../../utils/gridColumns";

const MissingSpecsLayout = ({ insight, subtitleDetails }) => {
  const { data, isLoading, isError } = useMissingSpecsDrillDown(insight.id);
  
  if (isLoading) {
    return <Loader />;
  }
    
  if (isError) {
    return (
      <ErrorMessage />
    );
  }

  const columns = [
    {
      field: 'batch_id',
      headerName: 'Batch',
      flex: 1,
      minWidth: 90,
    },
    {
      field: 'item_code',
      headerName: 'Item Code',
      renderCell: renderCellWithTooltip,
      flex: 1.2,
      minWidth: 120,
    },
    {
      field: 'weight_pack',
      headerName: 'Weight Pack',
      flex: 1,
      minWidth: 90,
      ...numberColumn(),
    },
    {
      field: 'quantity_by_weight_pack',
      headerName: 'Quantity By Weight Pack',
      flex: 1,
      minWidth: 90,
      ...numberColumn(),
    },
    {
      field: 'used_quantity',
      headerName: 'Used Quantity',
      flex: 1,
      minWidth: 90,
      ...numberColumn(),
    },
    {
      field: 'utilization',
      headerName: 'Utilized Quantity',
      flex: 1,
      minWidth: 90,
      ...numberColumn(),
    },
  ];

  const aggregateRow = {
    batch_id: 'Total',
    item_code: '',
    utilization: data['used_existing_item_codes'].map((item) => item.utilization).reduce((a, b) => a + b, 0),
    quantity_by_weight_pack: data['used_existing_item_codes'].length > 0 ? data['used_existing_item_codes'].map((item) => item.quantity_by_weight_pack).reduce((a, b) => a + b, 0).toFixed(2): 'N/A',
    used_quantity: data['used_existing_item_codes'].length > 0 ? data['used_existing_item_codes'].map((item) => item.used_quantity).reduce((a, b) => a + b, 0).toFixed(2): 'N/A',
  };

  return (
    <Stack spacing={1}>
      <DrillDownHeader
        title={insight.title}
        subtitle={<DetailsList
          details={subtitleDetails}
          itemsPerRow={3}
          valueGridColSpan={6}
          labelGridColSpan={6}
        />}
      />
      <strong>Current Fulfillment of Affected Contracts</strong>
      <ContractedDemandTable data={data['current_fulfillment']} demandColumnsToShow={3} view="month" isAutoHeight={true}/>
      <br/>
      <strong>Existing Batches That Could Be Utilized</strong>
      <ScopeDataGrid
        rows={[...data['used_existing_item_codes'], aggregateRow]}
        columns={columns}
        getRowId={(row) => `${row['batch_id']}_${row['item_code']}_${row['utilization']}`}
        style={{
          width: '100%',
        }}
        getRowClassName={(params) => params.id === `${aggregateRow.batch_id}_${aggregateRow.item_code}_${aggregateRow.utilization}` ? 'production-recommendation-table-aggregate-row' : ''}
        isAutoHeight={true}
        initialState={{
          pagination: {
            pageSize: [...data['used_existing_item_codes'], aggregateRow].length,
          },
        }}
      />
    </Stack>
  );
};

MissingSpecsLayout.propTypes = {
  insight: PropTypes.object.isRequired,
  subtitleDetails: detailsPropTypeShape,
};

export default MissingSpecsLayout;
