import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { 
  NavLink, 
  useLocation,
} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import {
  MRPIcon,
  InsightIcon,
  DemandIcon,
  BlendIcon,
  SettingsIcon,
  FulfillmentIcon,
  DashboardIcon,
} from './ScopeIcons';
import { useSendAnalytics } from '../analytics/analytics';
import eventTypes from '../analytics/eventTypes';

const ICON_VIEWBOX = '-10 0 50 50';
const ICON_WRAPPER_PROPS = { width: 40, height: 60 };


export const AppLeftToolBox = () => {
  const sendAnalytics = useSendAnalytics();
  const location = useLocation();

  const SendAnalyticsOnNavigate = (to) => {
    sendAnalytics( eventTypes.global.navigation, { from: location.pathname, to });
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
      }}
      display="flex"
      alignItems="flex-start"
    >
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item justifyContent="center" alignItems="center">
          <IconButton
            component={NavLink}
            to="/insights"
            sx={{ '&.active': { color: '#08E0EA' } }}
            onClick={() => SendAnalyticsOnNavigate('insights')}
          >
            <InsightIcon viewBox={ICON_VIEWBOX} sx={ICON_WRAPPER_PROPS} />
          </IconButton>
        </Grid>
        <Grid item justifyContent="center" alignItems="center">
          <IconButton
            component={NavLink}
            to="/mrp"
            sx={{ '&.active': { color: '#08E0EA' } }}
            onClick={() => SendAnalyticsOnNavigate('mrp')}
          >
            <MRPIcon viewBox={ICON_VIEWBOX} sx={ICON_WRAPPER_PROPS} />
          </IconButton>
        </Grid>
        <Grid item justifyContent="center" alignItems="center">
          <IconButton
            component={NavLink}
            to="/fulfillment"
            sx={{ '&.active': { color: '#08E0EA' } }}
            onClick={() => SendAnalyticsOnNavigate('fulfillment')}
          >
            <FulfillmentIcon viewBox={ICON_VIEWBOX} sx={ICON_WRAPPER_PROPS} />
          </IconButton>
        </Grid>
        <Grid item justifyContent="center" alignItems="center">
          <IconButton
            component={NavLink}
            to="/dashboard"
            sx={{ '&.active': { color: '#08E0EA' } }}
            onClick={() => SendAnalyticsOnNavigate('dashboard')}
          >
            <DashboardIcon viewBox={ICON_VIEWBOX} sx={{ width: 50, height: 60 }} />
          </IconButton>
        </Grid>
        <Grid item justifyContent="center" alignItems="center">
          <IconButton
            disabled={true}
          >
            <DemandIcon viewBox={ICON_VIEWBOX} sx={ICON_WRAPPER_PROPS} />
          </IconButton>
        </Grid>
        <Grid item justifyContent="center" alignItems="center">
          <IconButton
            disabled={true}
          >
            <BlendIcon viewBox={ICON_VIEWBOX} sx={ICON_WRAPPER_PROPS} />
          </IconButton>
        </Grid>
        <Grid item justifyContent="center" alignItems="center">
          <IconButton
            component={NavLink}
            to="/settings"
            sx={{ '&.active': { color: '#08E0EA' } }}
            onClick={() => SendAnalyticsOnNavigate('settings')}
          >
            <SettingsIcon viewBox={ICON_VIEWBOX} sx={ICON_WRAPPER_PROPS} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppLeftToolBox;
