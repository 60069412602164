import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';

const filterChipsPropTypeShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
  onDelete: PropTypes.func,
});

const FilterChips = ({ filterChips }) => (
  <React.Fragment>
    {filterChips.map(({ label, value, onDelete }) => (
      <Chip
        label={`${_.startCase(label)}: ${value}`}
        variant="outlined"
        color="primary"
        key={label + value}
        onDelete={() => onDelete(label, value)}
        sx={{ margin: '0 0.1rem' }}
      />
    ))}
  </React.Fragment>
);

FilterChips.propTypes = {
  filterChips: PropTypes.arrayOf(filterChipsPropTypeShape).isRequired,
};

export default FilterChips;
