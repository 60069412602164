import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import './styles.scss';


const UserMenuButton = (props) => {
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutButtonClick = () => {
    logout({ returnTo: window.location.origin });
    handleClose();
  };

  if (!user) {
    return <div/>;
  }
  
  return (
    <React.Fragment>
      <Chip
        avatar={
          <Avatar
            className="user-menu-button-avatar"
          >
            {user.given_name[0] + user.family_name[0]}
          </Avatar>
        }
        label={user.name}
        className='user-menu-button'
        onClick={handleClick}
        id='user-menu-button'
      />
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-menu-button',
        }}
      >
        <MenuItem onClick={onLogoutButtonClick}>Logout</MenuItem>
      </Menu>  
    </React.Fragment>
  );
};

UserMenuButton.propTypes = {};

export default UserMenuButton;
