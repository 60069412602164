import React from 'react';
import PropTypes from 'prop-types';
import ScopeToggle from "./ScopeToggle";

const VirtualContractsToggle = ({ selectedValue, handleToggle }) => (
  <ScopeToggle
    handleToggle={handleToggle}
    selectedValue={selectedValue}
    toggleValues={[{ key: 'include', value: 'All Demand' }, { key: 'exclude', value: 'Contracted Demand' }]}
  />
);

VirtualContractsToggle.propTypes = {
  selectedValue: PropTypes.oneOf(['include', 'exclude']).isRequired,
  handleToggle: PropTypes.func.isRequired,
};

export default VirtualContractsToggle;
