import React from 'react';
import PropTypes from 'prop-types';
import FilterCheckboxList from '../../components/filterCheckboxList';
import NestedFilterList from '../../components/nestedFilterList/NestedFilterList';
import ErrorMessage from "../../components/ErrorMessage";
import Loader from '../../components/Loader';
import FiltersList from '../../components/FiltersList';
import { 
  useFulfillmentFilterValues,
  useFulfillmentNestedFilterValues,
} from './hooks';

const FulfillmentFilters = ({ selectedSites, selectedCustomers, setSelectedSites, setSelectedCustomers, appliedNestedFilter, setAppliedNestedFilter }) => {
  const [expanded, setExpanded] = React.useState({ "site": true, "customer": true, product_family: true });

  const { data: sites, isLoading: isLoadingSites, isError: isSitesError } = useFulfillmentFilterValues('site');
  const { data: customers, isLoading: isLoadingCustomers, isError: isCustomersError } = useFulfillmentFilterValues('customer');
  const { data: nestedFilterValues, isLoading: isLoadingNestedFilterValues, isError: isNestedFilterValuesError } = useFulfillmentNestedFilterValues('product_family', 'item_code');

  if (isLoadingSites  || isLoadingCustomers || isLoadingNestedFilterValues) {
    return <Loader/>;
  }

  if (isSitesError || isCustomersError || isNestedFilterValuesError) {
    return <ErrorMessage/>;
  }

  const handleClick = (category) => {
    const expandState = { ...expanded };
    expandState[category] = !expandState[category];
    setExpanded(expandState);
  };

  const allFilters = [
    {
      category: "site",
      values: sites,
      appliedFilter: selectedSites,
      setAppliedFilter: setSelectedSites,
      layout: FilterCheckboxList,
    },
    {
      category: "product_family",
      values: nestedFilterValues,
      appliedFilter: appliedNestedFilter,
      setAppliedFilter: setAppliedNestedFilter,
      layout: NestedFilterList,
    },
    {
      category: "customer",
      values: customers,
      appliedFilter: selectedCustomers,
      setAppliedFilter: setSelectedCustomers,
      layout: FilterCheckboxList,
    },
  ];
  
  return (
    <FiltersList
      filters={allFilters}
      expanded={expanded}
      handleClick={handleClick}
    />
  );
};

FulfillmentFilters.propTypes = {
  selectedCustomers: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedSites: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedCustomers: PropTypes.func.isRequired,
  setSelectedSites: PropTypes.func.isRequired,
  appliedNestedFilter: PropTypes.arrayOf(PropTypes.string),
  setAppliedNestedFilter: PropTypes.func.isRequired,
};

export default FulfillmentFilters;
