import React from "react";
import PropTypes from "prop-types";

import KPIWithGraph from "../../../components/widgetLib/KpiWithGraph";
import Loader from "../../../components/Loader";
import ErrorMessage from "../../../components/ErrorMessage";
import { useOverstock } from "../hooks";
import { kpiColor } from "./constants";
import BarGraph from "../../../components/widgetLib/BaGraph";

const ExpectedOverstock = ({ filters, elementHeight }) => {
  const { data, isLoading, isError } = useOverstock(filters);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <ErrorMessage />;
  }

  const color = kpiColor['expected_overstock'][data.kpiDirection];
  const graphHeight = elementHeight > 300 ? elementHeight/1.5 : elementHeight/2;
  return (
    <KPIWithGraph
      title={'Expected overstock'}
      value={data.value}
      isCurrency={true}
      unit={'USD'}
      className={'dashboard-kpi'}
      color={color}
      kpiPercent={data.kpiPercent.replace('-', '').replace('+', '')}
      kpiDirection={data.kpiDirection}
      content={<BarGraph data={data.graphData} elementHeight={graphHeight}/>}
    />);
};

ExpectedOverstock.propTypes = {
  filters: PropTypes.object.isRequired,
  elementHeight: PropTypes.number,
};

export default ExpectedOverstock;