import React from "react";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import Stack from "@mui/material/Stack";
import FileDownloadOutlined from "@mui/icons-material/FileDownloadOutlined";
import { useNestedMRPFilterValues } from './hooks';
import {
  useItemCodes,
  useMRPShowOnlyError,
  useMRPTablesSort,
} from '../../state/hooks';
import FilterChips from '../../components/FilterChips';
import MRPColorLegend from "../../components/MRPColorLegend";
import { useNestedFilterChips } from '../../components/nestedFilterList/helpers';
import { getTodayForExport } from "../../utils/dateTime";
import { useSendAnalytics } from "../../analytics/analytics";
import eventTypes from "../../analytics/eventTypes";


const MRPTablesHeader = ({ csvData, csvHeaders }) => {
  const { itemCodes, setItemCodes } = useItemCodes();
  const filterChips = useNestedFilterChips({
    useFilterValues: useNestedMRPFilterValues,
    selectedFilters: itemCodes,
    setSelectedFilters: setItemCodes,
    analyticsEventType: eventTypes.mrp.mrpFilterChipRemoved,
  });
  const { mrpShowOnlyError, setMrpShowOnlyError } = useMRPShowOnlyError();
  const { mrpTableSort, setMrpTableSort } = useMRPTablesSort();
  const sendAnalytics = useSendAnalytics();

  const handleShowOnlyErrorToggle = (value) => {
    sendAnalytics(eventTypes.mrp.mrpShowOnlyErrorToggle, { checked: value });
    setMrpShowOnlyError(value);
  };

  const handleMRPTablesSort = (event) => {
    const newMode = event.target.value;
    sendAnalytics(eventTypes.mrp.mrpTablesSort, { mode: newMode });
    setMrpTableSort(newMode);
  };

  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        justifyItems={"center"}
        alignItems="center"
      >
        <Grid
          container
          item
          xs={6}
          direction="row"
          spacing={1}
          sx={{ height: '2rem' }}
          justifyContent="flex-start"
        >
          <Stack spacing={1} style={{ height: '100%' }}>
            <Stack
              direction="row"
              spacing={1}
            >
              <Select
                value={mrpTableSort}
                onChange={handleMRPTablesSort}
                color="primary"
                displayEmpty
                className="mrp-tables-sort-selection"
                name="mrp-tables-sort-select"
              >
                <MenuItem value={""}>Sort tables</MenuItem>
                <MenuItem value={"oos_period_asc"}>OOS Period (ASC)</MenuItem>
                <MenuItem value={"oos_period_desc"}>OOS Period (DESC)</MenuItem>
                <MenuItem value={"stock_on_hand_asc"}>Stock on Hand (ASC)</MenuItem>
                <MenuItem value={"stock_on_hand_desc"}>Stock on Hand (DESC)</MenuItem>
                <MenuItem value={"item_code_asc"}>Item Code (ASC)</MenuItem>
                <MenuItem value={"item_code_desc"}>Item Code (DESC)</MenuItem>
              </Select>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          container
          item
          xs={6}
          direction="row"
          spacing={1}
          className="mrp-download-data"
          justifyContent="flex-end"
        >
          {csvData && csvData.length > 0 &&
        <Button
          startIcon={<FileDownloadOutlined/>}
          onClick={() => sendAnalytics(eventTypes.mrp.mrpTablesExportedToCSV, {})}>
          <CSVLink data={csvData} headers={csvHeaders} filename={`mrp_data_${getTodayForExport()}`}>EXPORT</CSVLink>
        </Button>
          }
          <FormControlLabel
            sx={{ m: 1 }}
            label="Show only error"
            title={"Stock Cover always exceeds 15, OR is below 3 at least once."}
            control={
              <Checkbox
                id={`mrp-error-only`}
                checked={mrpShowOnlyError}
                onChange={(event) => handleShowOnlyErrorToggle(event.target.checked)}
              />
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        direction="row"
        spacing={1}
        className="applied-filters-area"
      >
        <FilterChips filterChips={filterChips} />
      </Grid>
      <Grid
        item
        container
        direction="row"
        xs={12}
        justifyContent="center"
        className="mrp-page-color-legend"
      >
        <MRPColorLegend />
      </Grid>
    </React.Fragment>
  );
};

MRPTablesHeader.propTypes = {
  csvData: PropTypes.array,
  csvHeaders: PropTypes.array,
};
export default MRPTablesHeader;
