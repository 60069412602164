import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import IconButton from "@mui/material/IconButton";
import { currencyFormatter } from "../../utils/currency";

const KPIWithGraph = ({ title, subtitle, value, isCurrency, unit, kpiPercent, kpiDirection, color, content, ...props }) => (
  <Card {...props}>
    <CardContent className="kpi-content">
      <Grid container justify="space-around">
        <Grid item xs={8} container direction="column" textAlign={'start'}>
          <Grid item >
            <Typography variant="subtitle1" className="kpi-title">{title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" className="kpi-value">
              {isCurrency ? currencyFormatter(unit, 2, 2, 'compact').format(value) : `${value.toFixed(0).toLocaleString()} ${unit}`}
            </Typography>
          </Grid>
          {subtitle && subtitle !== '' ?
            <Grid item>
              <Typography variant="subtitle2" className="kpi-subtitle">{subtitle}</Typography>
            </Grid>: null
          }
        </Grid>
        <Grid item container xs={4} direction={"row"}>
          <Grid
            item
            xs={12}
            container
            sx={{ height: '40px', backgroundColor: color.bg, borderRadius: '0.9rem' }}
            alignItems={'center'}
            justifyContent={'space-evenly'}
          >
            <IconButton size={'small'} sx={{ backgroundColor: 'rgba(0, 0, 0, .2)' }}>
              { kpiDirection === 'positive' ?
                <ArrowUpwardOutlinedIcon fontSize={'small'} color={'cardText'} /> :<ArrowDownwardOutlinedIcon fontSize={'small'} color={'cardText'} />
              }
            </IconButton>
            <Typography variant={'subtitle1'} className="kpi-percent" fontSize={{ xs: '12px', xl: '14px' } } sx={{ color: color.color }} align={'right'}>
              {`${kpiDirection === 'positive' ? '+': '-'}${kpiPercent}%`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle1'} className="kpi-subtitle" align={'center'}></Typography>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
    <CardContent className="kpi-content" sx={{ height: '30vh' }}>
      <Grid container justify="space-between" sx={{ height: '100%' }}>
        <Grid item xs={12} container direction="row" style={{ height: 'inherit', width: '100%' }}>
          {content}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

KPIWithGraph.defaultProps = {
  kpiDirection: 'positive',
  color: { color: 'green', bg: 'rgba(51, 170, 51, .2)' },
};

KPIWithGraph.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  value: PropTypes.number.isRequired,
  kpiDirection: PropTypes.string,
  color: PropTypes.object,
  kpiPercent: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  isCurrency: PropTypes.bool.isRequired,
  unit: PropTypes.string,
};

export default KPIWithGraph;
