import ColorLegend from "./widgetLib/ColorLegend";
import Palette from "../styling/palette.scss";

const MRPColorLegend = () => <ColorLegend
  legend={[
    {
      label: 'PO/GRPO',
      color: Palette.DarkThemeColorCodePink,
    },
    {
      label: 'GP',
      color: Palette.DarkThemeColorCodeOrange,
    },
    {
      label: 'PO/GRPO & GP',
      color: Palette.DarkThemeColorCodeYellow,
    },
    {
      label: 'PO/GRPO & Suggestion',
      color: Palette.DarkThemeColorCodeLightGreen,
    },
    {
      label: 'Suggestion & GP',
      color: Palette.DarkThemeColorCodeYellowGreen,
    },
    {
      label: 'PO/GRPO & Suggestion & GP',
      color: Palette.DarkThemeColorCodeDarkCyan,
    },
    {
      label: 'Suggestion',
      color: Palette.DarkThemeColorCodeCyan,
    },
    {
      label: 'Under Safety Stock',
      color: Palette.DarkThemeError,
    },
    {
      label: 'Over Stock',
      color: Palette.DarkThemeColorCodePurple,
    },
  ]}
/>;

export default MRPColorLegend;
