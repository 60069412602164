import { useBackendAPIGetRequest } from '../../connectors/apis';
import { convertObjectToURLString } from '../../connectors/utils';

export const useFulfillment = (view, mode, filters, virtualContractsView='include') => useBackendAPIGetRequest({
  queryKey: ['fulfillment', view, mode, filters, virtualContractsView],
  path: `/fulfillment?view=${view}&mode=${mode}&${convertObjectToURLString(filters)}&virtual_contracts_view=${virtualContractsView}`,
});

export const useFulfillmentFilterValues = (category) => useBackendAPIGetRequest({
  queryKey: ['fulfillmentFilterValues', category],
  path: `/fulfillment/filter_values/${category}`,
});

export const useFulfillmentNestedFilterValues = (parentCategory, childCataegory) => useBackendAPIGetRequest({
  queryKey: ['fulfillmentNestedFilterValues', parentCategory, childCataegory],
  path: `/fulfillment/nested_filter_values?parent_category=${parentCategory}&child_category=${childCataegory}`,
});
