import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const DrillDownHeader = ({ title, subtitle }) => (
  <React.Fragment>
    <Typography variant="h6">
      {title}
    </Typography>
    <div>
      {subtitle}
    </div>
  </React.Fragment>
);

DrillDownHeader.defaultProps = {
  subtitle: '',
};

DrillDownHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.any.isRequired,
};

export default DrillDownHeader;
