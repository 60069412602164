import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import MRPTableLayout from "./MRPTableLayout";
import ErrorMessage from "../../components/ErrorMessage";
import Loader from "../../components/Loader";
import MRPTablesHeader from "./MRPTablesHeader";
import { useMRPTables } from "./hooks";
import {
  useProductFamilyAndItemCodes,
  useSite,
  useMRPShowOnlyError,
  useMRPTablesSort,
} from '../../state/hooks';
import { useSendAnalytics } from '../../analytics/analytics';
import eventTypes from '../../analytics/eventTypes';
import { getWeekMonthForDisplay } from "../../utils/dateTime";
import { MRP_ROW_TYPE_ON_HAND } from '../../constants';

const MRPTablesList = ({ selectedItemCode, setSelectedItemCode, view, virtualContractsView }) => {
  const { site } = useSite();
  const { productFamily, itemCodes } = useProductFamilyAndItemCodes();
  const { mrpShowOnlyError } = useMRPShowOnlyError();
  const { mrpTableSort } = useMRPTablesSort();
  const sendAnalytics = useSendAnalytics();

  const { data, isLoading, isError } = useMRPTables(site, productFamily, itemCodes, null, view, mrpShowOnlyError, virtualContractsView);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  const { specs, data_columns } = data;

  const handleSort = () => {
    let sortedItems = [];
    let filteredItems;
    switch (mrpTableSort) {
      case '':
        return Object.keys(specs);
      case 'item_code_asc':
        return Object.keys(specs).sort();
      case 'item_code_desc':
        return Object.keys(specs).reverse();
      case 'stock_on_hand_asc':
        filteredItems = dataAsArray.filter((row) => row.variable === "on_hand");
        sortedItems = filteredItems.sort(function (a, b) {
          return (a.today - b.today);
        });
        return Object.values(sortedItems).map((i) => i['item_code']);
      case 'stock_on_hand_desc':
        filteredItems = dataAsArray.filter((row) => row.variable === "on_hand");
        sortedItems = filteredItems.sort(function (a, b) {
          return (b.today - a.today);
        });
        return Object.values(sortedItems).map((i) => i['item_code']);
      case 'oos_period_asc':
        filteredItems = dataAsArray.filter((row) => row.variable === "stock_cover");
        filteredItems.map((elem) => elem.oosPeriod = Object.keys(elem).filter((key) => data_columns.includes(key) && elem[key] < 3).length);
        sortedItems = filteredItems.sort(function (a, b) {
          return (a.oosPeriod - b.oosPeriod);
        });
        return Object.values(sortedItems).map((i) => i['item_code']);
      case 'oos_period_desc':
        filteredItems = dataAsArray.filter((row) => row.variable === "stock_cover");
        filteredItems.map((elem) => elem.oosPeriod = Object.keys(elem).filter((key) => data_columns.includes(key) && elem[key] < 3).length);
        sortedItems = filteredItems.sort(function (a, b) {
          return (b.oosPeriod - a.oosPeriod);
        });
        return Object.values(sortedItems).map((i) => i['item_code']);
      default:
        return Object.keys(specs);
    }
  };

  const csvHeaders = [
    { label: 'Product Family', key: 'product_family' },
    { label: 'Item Code', key: 'item_code' },
    { label: 'Site', key: 'site' },
    { label: 'Variable', key: 'variable' },
    { label: 'Today', key: 'today' },
  ];

  let dataAsArray = [].concat(...Object.values(specs).map((i) => i['rows']));
  dataAsArray = dataAsArray.map((item) => ({
    ...item,
    today: (item.variable === MRP_ROW_TYPE_ON_HAND ? item.today : ''),
  }));
  if (dataAsArray.length > 0) {
    dataAsArray = dataAsArray.filter((row) => row.variable !== "total_relevant_docnums");
    const keys = Object.keys(dataAsArray[0]);
    keys.forEach((k) => {
      const values = k.split("_");
      if (values.length === 2 && !isNaN(values[0])) {
        const timeLabel = getWeekMonthForDisplay(view, k);
        csvHeaders.push({ label: `${timeLabel}`, key: k });
      }
    });
  }

  const specItemCodes = handleSort();

  const onItemCodeClick = (index) => () => {
    if (selectedItemCode === specItemCodes[index]) {
      sendAnalytics(eventTypes.mrp.mrpItemCodeUnselected);
      setSelectedItemCode("");
    } else {
      sendAnalytics(eventTypes.mrp.mrpItemCodeSelected, { item_code: specItemCodes[index] });
      setSelectedItemCode(specItemCodes[index]);
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Grid
        container
        item
        className="mrp-tables-header"
        alignItems="flex-start"
        spacing={1}
      >
        <MRPTablesHeader
          csvData={dataAsArray}
          csvHeaders={csvHeaders}
        />
      </Grid>
      <Grid
        container
        item
        spacing={1}
        sx={{ mt: 0 }}
        className="mrp-tables-container"
      >
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              itemCount={specItemCodes.length}
              height={height}
              width={width}
              itemSize={280} // I don't like having to hard-code the item height here
              overscanCount={3}
            >
              {({ index, style }) => (
                <Grid
                  container
                  key={specItemCodes[index]}
                  className={`spec-table-area ${
                    selectedItemCode === specItemCodes[index] ? "selected" : ""
                  }`}
                  style={style}
                >
                  <MRPTableLayout
                    data={specs[specItemCodes[index]]}
                    itemCode={specItemCodes[index]}
                    onItemCodeClick={onItemCodeClick(index)}
                    dataColumns={data_columns}
                    view={view}
                    virtualContractsView={virtualContractsView}
                  />
                </Grid>
              )}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Grid>
    </div>
  );
};

MRPTablesList.propTypes = {
  selectedItemCode: PropTypes.string,
  setSelectedItemCode: PropTypes.func.isRequired,
  view: PropTypes.oneOf(['week', 'month']),
  virtualContractsView: PropTypes.oneOf(['include', 'exclude']),
};

MRPTablesList.defaultProps = {
  view: 'week',
  virtualContractsView: 'include',
};

export default MRPTablesList;
