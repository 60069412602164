import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import { useContractNextMonthPO } from './hooks';
import ScopeDataGrid from "../../components/widgetLib/ScopeDataGrid";
import { numberColumn, renderCellWithTooltip } from "../../utils/gridColumns";

const ContractPONextMonth = ({ contractId }) => {
  const { data, isLoading, isError } = useContractNextMonthPO(contractId);

  const columns = [
    {
      field: 'site',
      headerName: 'Site',
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'product_family',
      headerName: 'Product',
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'brix_range',
      headerName: 'Brix',
      flex: 1,
      ...numberColumn(1),
    },
    {
      field: 'ratio_range',
      headerName: 'Ratio',
      flex: 1,
      renderCell: renderCellWithTooltip,
    },
    {
      field: 'grade',
      headerName: 'Grade',
      flex: 1,
      renderCell: renderCellWithTooltip,
    },
    {
      field: 'color',
      headerName: 'Color',
      flex: 1,
      renderCell: renderCellWithTooltip,
    },
    {
      field: 'origin',
      headerName: 'Origin',
      flex: 1,
      renderCell: renderCellWithTooltip,
    },
    {
      field: 'missing_quantity_next_3_months',
      headerName: 'Missing Quantity Next 3 Months',
      flex: 1,
      ...numberColumn(),
    },
    {
      field: 'predicted_inventory_unlock',
      headerName: 'Predicted Inventory Unlock',
      flex: 1,
      ...numberColumn(),
    },
  ];
    
  if (isLoading) {
    return <Loader/>;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  if (data.length > 0) {
    return (
      <>
        <Typography className="contract-section-title">
            PO's need to be raised for next month
        </Typography>
        <ScopeDataGrid
          columns={columns}
          rows={data}
          isCellEditable={() => false}
          getRowId={(row) => row.id}
          style={{
            width: '100%',
          }}
          isAutoHeight={true}
        />
      </>
    );
  }
  return <></>;
};

ContractPONextMonth.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default ContractPONextMonth;
