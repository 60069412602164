import IncomingDetails from "./containers/MRP/IncomingDetails";
import MRPDemandDrillDown from "./containers/MRPDemandDrillDown/MRPDemandDrillDown";

export const MRP_ROW_TYPE_INCOMING = "incoming";
export const MRP_ROW_TYPE_DEMAND = "demand";
export const MRP_ROW_TYPE_ON_HAND = "on_hand";
export const MRP_ROW_TYPE_STOCK_COVER = "stock_cover";

export const MRP_TABLE_ROWS = [
  MRP_ROW_TYPE_INCOMING,
  MRP_ROW_TYPE_DEMAND,
  MRP_ROW_TYPE_ON_HAND,
  MRP_ROW_TYPE_STOCK_COVER,
];

export const REDIRECT_AFTER_LOGIN_PATH_KEY = 'scopeRedirectAfterLoginPath';

export const AUTH0_AUTHORIZATION_SCOPE = 'openid%20profile%20email';

export const APP_WINDOW_HEIGHT = 93;

export const GRID_COL_TYPE_NUMBER = 'number';
export const GRID_COL_TYPE_STRING = 'string';
export const GRID_COL_TYPE_CURRENCY = 'currency';
export const GRID_COL_TYPE_PERCENT = 'percent';
export const GRID_COL_TYPE_DATE = 'date';
export const GRID_COL_TYPE_TIME_PERIOD = 'time_period';

export const renderMRPDrillDownByType = {
  demand: MRPDemandDrillDown,
  incoming: IncomingDetails,
};

export const mrpTableText = {
  incoming:
        {
          modalText:
                <>An incoming stock refers to the inventory or goods that are expected to be received by a business or
                    organization from a supplier or manufacturer. It represents the quantity and value of products that
                    have
                    been ordered but have not yet been received and added to the business's inventory. Once the incoming
                    stock
                    is received, it will be inspected and accounted for before being added to the business's stock
                    levels.
                <p><b>Types of incoming stock:</b></p>
                <ul>
                  <li>Scheduled receipt of goods from an open Purchase Order (PO)</li>
                  <li>Scheduled receipt of goods from an open Goods Receipt PO</li>
                  <li>Scheduled receipt of goods from an open General Production (GP)</li>
                  <li>Stock ordering suggestion that is based on demand, inventory and production schedules in
                            order
                            to
                            meet sufficient stock cover
                  </li>
                </ul>
                </>,
          tooltip: <>Represents the scheduled receipt of goods that are expected to arrive at a certain date to
                fulfill the demand</>,
        },
  demand:
        {
          modalText: <>Demand refers to the quantity of a product that customers require within a specific time frame.
                Variety of
                factors to calculate demand are being used, such as historical sales data, customer orders, and
                forecasts.
          <p><b>Key factors:</b></p>
          <ul>
            <li>The demand encompasses quantities utilized from a particular product across various contracts,
                        including contracts for other item codes, as well as unfulfilled quantities from contracts for
                        the
                        same item code.
            </li>
            <li>All work orders / sales orders / general production that are already fully allocated are removed
                        from demand calculation.
            </li>
          </ul>
          </>,
          tooltip: <>Expected quantity of a product required by customers, which helps in determining the materials
                and components needed for production.
          </>,
        },
  on_hand:
        {
          modalText: <>Stock on hand represents the quantity of finished goods, raw materials, and components that a
                business has in
                its warehouse or production facility. It helps determine the availability of materials for production
                and
                the ability of the business to fulfill customer orders.
          <p><b>Definitions:</b></p>
          <ul>
            <li>The On Hand inventory is calculated as the sum of the previous On Hand inventory, incoming
                        stock,
                        and adjustments, minus the demand for the period:
            <i>On Hand = previous On Hand + incoming stock - demand</i>
            </li>
            <li>Stock that is already fully allocated for sale order/work order is reduced from the stock on hand.</li>
          </ul>
          </>,
          tooltip: <>Actual physical inventory available at a certain timeframe</>,
        },
  stock_cover:
        {
          modalText: <>Stock cover refers to the number of weeks that the current inventory levels are expected to
                last based on the
                projected demand. It represents the amount of time before the stock levels are depleted and new
                inventory
                needs to be ordered or produced to meet customer demand. MRP systems use Stock Cover to calculate the
                reorder point and the safety stock levels, which are used to optimize inventory levels and ensure that
                customer orders are fulfilled on time.
          </>,
          tooltip: <>The amount of time a certain item code inventory level is expected to last, based on the rate of
                consumption or usage</>,
        },
};