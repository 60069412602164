import Tooltip from "@mui/material/Tooltip";
import {
  currencyFormatter,
  currencySorter,
} from "./currency";

const timePeriodSorter = (a, b) => a.value - b.value;

export const percentColumn = () => ({
  type: 'number',
  align: 'left',
  headerAlign: 'left',
  valueGetter: ({ value }) => {
    if (value === 'N/A') {
      return value;
    }
    return typeof value === 'string' ? Number(value.replace('%', '')) :value;
  },
  valueFormatter: ({ value }) => value === 'N/A' ? value: `${value}%`,
});

export const numberColumn = (fractionDigits = 2) => ({
  type: 'number',
  align: 'left',
  headerAlign: 'left',
  valueGetter: ({ value }) => isNaN(value) ? value: parseFloat(value).toFixed(fractionDigits),
});

export const dateColumn = () => ({
  type: 'date',
  valueGetter: ({ value }) => {
    if (value === 'N/A') { return 'N/A'; }
    return value && value.indexOf(',') > -1 ? value.split(',').map((v) => new Date(v)).join(', ') : value && new Date(value);
  },
  valueFormatter: ({ value }) => {
    if (value && typeof value === 'string' && value.indexOf(',') < 0 && (value === 'N/A' || isNaN(new Date(value)))) {
      return 'N/A';
    }
    return typeof value === 'string' && value.indexOf(',') > -1 ? value.split(',').map((v) => isNaN(new Date(v)) ? 'N/A': new Date(v).toLocaleDateString('he-IL')).join(', ') : value && value.toLocaleDateString('he-IL');
  },
  renderCell: (params) => {
    let dateValue = '';
    if (params.value && typeof params.value === 'string' && params.value.indexOf(',') < 0 && (params.value === 'N/A' || isNaN(new Date(params.value)))) {
      dateValue = 'N/A';
    } else {
      dateValue = typeof value === 'string' && params.value.indexOf(',') > -1 ? params.value.split(',').map((v) => isNaN(new Date(v)) ? 'N/A': new Date(v).toLocaleDateString('he-IL')).join(', ') : params.value && params.value.toLocaleDateString('he-IL');
    }
    return <Tooltip title={dateValue}>
      <span style={{ overflowX: "hidden", textOverflow: "ellipsis" }}>{dateValue}</span>
    </Tooltip>;
  },
});

export const currencyColumn = (minimumFractionDigits = 2, maximumFractionDigits = 2, notation = "standard") => ({
  type: 'number',
  align: 'left',
  headerAlign: 'left',
  valueFormatter: ({ value }) => value.value === -1 ? 'N/A' : value.currency === 'N/A' ? 'N/A' : currencyFormatter(value.currency, minimumFractionDigits, maximumFractionDigits, notation).format(value.value),
  sortComparator: currencySorter,
});

export const periodColumn = () => ({
  type: 'number',
  align: 'left',
  headerAlign: 'left',
  valueGetter: ({ value }) => value.trim() === 'N/A' ? { value: -1000, period: 'weeks' }: { value: Number(value.split(' ')[0]), period: value.split(' ')[1] },
  valueFormatter: ({ value }) => value.value === -1000 ? 'N/A' : `${value.value} ${value.period}`,
  sortComparator: timePeriodSorter,
});

export const renderCellWithTooltip = (params) => <Tooltip title={params.value}>
  <span style={{ overflowX: "hidden", textOverflow: "ellipsis" }}>{params.value}</span>
</Tooltip>;