import React from 'react';
import PropTypes from 'prop-types';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

const ScopeToggle = ({ selectedValue, handleToggle, toggleValues }) => (
  <ToggleButtonGroup
    value={selectedValue}
    size="small"
    exclusive
    onChange={handleToggle}
    color="primary"
    style={{ height: '2rem' }}
  >
    {toggleValues.map((toggleItem, index) => (
      <ToggleButton value={toggleItem.key} key={`toggle-item-${index}`}>
        {toggleItem.value}
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
);

ScopeToggle.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  handleToggle: PropTypes.func.isRequired,
  toggleValues: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ScopeToggle;
