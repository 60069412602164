import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { 
  useContractOrders,
  useContractOrdersGraph,
} from './hooks';
import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import ScopeDataGrid from '../../components/widgetLib/ScopeDataGrid';
import OrdersOverTimeGraph from '../../components/OrdersOverTimeGraph';
import {
  dateColumn,
  numberColumn,
  renderCellWithTooltip,
} from "../../utils/gridColumns";
import { useSendAnalytics } from '../../analytics/analytics';
import eventTypes from '../../analytics/eventTypes';

const ContractOrders = ({ contractId }) => {
  const { data: contractOrders, isLoading: isLoadingOrders, isError: isOrdersError } = useContractOrders(contractId);
  const { data: graphData, isLoading: isLoadingGraph, isError: isGraphError } = useContractOrdersGraph(contractId);
  const [graphMode, setGraphMode] = React.useState('fulfillmentRate');
  const sendAnalytics = useSendAnalytics();

  const columns = [
    {
      field: 'DocNum_rdr',
      headerName: 'Order ID',
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'DocNum_invoice',
      headerName: 'Invoice ID',
      renderCell: renderCellWithTooltip,
      flex: 1,
    },
    {
      field: 'Quantity_rdr',
      headerName: 'Quantity',
      flex: 1,
      ...numberColumn(1),
    },
    {
      field: 'ShipDate',
      headerName: 'Shipping Date',
      flex: 1,
      ...dateColumn(),
    },
  ];

  if (isLoadingOrders || isLoadingGraph) {
    return <Loader/>;
  }

  if (isOrdersError || isGraphError) {
    return <ErrorMessage />;
  }

  const handleToggleMode = (_, newMode) => {
    if (newMode !== null){
      sendAnalytics(eventTypes.contract.contractOrdersGraphModeToggle, { mode: newMode });
      setGraphMode(newMode);
    }
  };

  return (
    <Stack spacing={1} style={{ width: '100%' }} sx={{ height: '100%', minHeight: '10%' }}>
      <Stack direction="row" style={{ height: '2rem', width: '100%' }} justifyContent="flex-end">
        <ToggleButtonGroup
          value={graphMode}
          size="small"
          exclusive
          onChange={handleToggleMode}
          color="primary"
          style={{ height: '2rem' }}
        >
          <ToggleButton 
            value="orders"
            className="contract-orders-graph-toggle-button"
          >
            Orders
          </ToggleButton>
          <ToggleButton 
            value="fulfillmentRate"
            className="contract-orders-graph-toggle-button"
          >
            Fulfillment Rate
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <div style={{ height: '20vh', width: '100%' }}>
        <OrdersOverTimeGraph data={graphData} mode={graphMode} />
      </div>
      <ScopeDataGrid
        columns={columns}
        rows={contractOrders}
        isCellEditable={() => false}
        getRowId={(row) => `${row['DocNum_rdr']}_${row['DocNum_invoice']}`}
        exportFileName={_.snakeCase(`contract_${contractId}_orders`)}
        style={{
          width: '100%',
        }}
        isAutoHeight={true}
      />
    </Stack>
  );
};

ContractOrders.propTypes = {
  contractId: PropTypes.string.isRequired,
};

export default ContractOrders;
