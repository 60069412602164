import React from 'react';
import PropTypes from 'prop-types';
import MRPLayout from './drillDownLayouts/MRPLayout';
import ContractLayout from './drillDownLayouts/ContractLayout';
import ProductionRecommendationLayout from './drillDownLayouts/ProductionRecommendationLayout';
import MissingSpecsLayout from './drillDownLayouts/MissingSpecsLayout';
import { parseDetailValue } from '../../components/widgetLib/DetailsList';

const renderDrillDownBySource = {
  missing_specs: MissingSpecsLayout,
  contract: ContractLayout,
  mrp: MRPLayout,
  incoming: MRPLayout,
  work_order: ProductionRecommendationLayout,
  fulfillment: ContractLayout,
};

const insightTypesWithoutDrilldown = [
  'open_deliveries_unfulfilled',
  'incoming_early',
];

export const hasDrilldown = (insightType, insightSource) => (
  Object.keys(renderDrillDownBySource).includes(insightSource) && !insightTypesWithoutDrilldown.includes(insightType)
);

const InsightDrillDown = ({ insightType, insight }) => {
  const columnTypes = insight['column_types'];
  const subtitleDetails = Object.keys(insight)
    .filter((key) => insight['drill_down_columns'].includes(key))
    .map((key) => ({ label: key, value: parseDetailValue(insight[key], columnTypes[key], key === 'Contract' && insightType.type !== 'incoming_delay') }));
  
  const Layout = renderDrillDownBySource[insightType.source];

  return (
    <div className="insights-drill-down-drawer-content">
      <Layout
        insight={insight}
        subtitleDetails={subtitleDetails}
      />
    </div>
  );
};

InsightDrillDown.propTypes = {
  insightType: PropTypes.object.isRequired,
  insight: PropTypes.object.isRequired,
};

export default InsightDrillDown;
